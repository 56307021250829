import React, { useState } from 'react';
import type { FC } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { IconFont, ProUpload } from '@easygo/components';
import { useTranslation } from 'next-i18next';
import { Auth } from '@easygo/components';
import styles from './index.module.less';

const enum UPLOAD_STATUS {
  /** 上传中 */
  UPLOADING = 'uploading',
  /** 上传完成 */
  DONE = 'done',
  /** 上传失败 */
  ERROR = 'error',
}
interface UploadFileProps {
  onChange?: any;
  value?: any;
}
const UploadFile: FC<UploadFileProps> = (props) => {
  const { t } = useTranslation('ocean');
  const { onChange, value } = props ?? {};
  const [uploading, setUploading] = useState<boolean>(false);

  /**
   * @method 文件上传
   */
  const onUploadChange = async (fileInfo: any) => {
    const { status } = fileInfo?.[0];
    const isUploading = status === UPLOAD_STATUS.UPLOADING;
    if (status === UPLOAD_STATUS.DONE) {
      onChange([{ url: fileInfo?.[0]?.response, name: fileInfo?.[0]?.originFileObj.name }]);
    }
    setUploading(isUploading);
  };

  return (
    <ProUpload multiple maxCount={1} disabled={uploading} showUploadList={false} onChange={onUploadChange} fileSize={10}>
      <div className={styles.uploadContent}>
        <Auth auth={!uploading} or={<LoadingOutlined />}>
          <IconFont className={styles.uploadIcon} type="easygo-icon-upload" />
        </Auth>
        <span className={styles.uploadText}>
          <Auth auth={!uploading} or={t('file_uploading')}>
            {value?.[0]?.name || t('click_drag_upload_file')}
          </Auth>
        </span>
      </div>
    </ProUpload>
  );
};

export default UploadFile;
