import type { CSSProperties } from 'react';
import { forwardRef } from 'react';
import React, { useEffect, useState, useImperativeHandle } from 'react';
import cls from 'classnames';
import { Empty, List, Image, Descriptions, Tooltip, Space } from 'antd';
import type { SelectProps } from 'antd';
import { useRequest } from 'ahooks';
import { Auth, IconFont, Select } from '@easygo/components';
import AccountModal from '../account-modal';
import { Action_Type, Action_Type_Label } from './constants';
import styles from './index.module.less';
import { useCurrencyCodeAndSymbol } from '@easygo/components/src/hooks/useMultipleCurrencyAmount';
import { CurrencyTrans } from '@easygo/utils/src/enums';

type Req = (...args: any) => Promise<any>;
interface SelectAccountInfoProps extends SelectProps {
  /** 下拉接口 */
  listServer: Req;
  /** 编辑接口 */
  editAccountServer?: Req;
  /** 创建接口 */
  createAccountServer?: Req;
  /** 移除接口 */
  deleteAccountServer?: Req;
  /** 获取详情接口 */
  getAccountInfoServer?: Req;
  /** 获取城市接口 */
  getCityServer?: Req;
  onChange?: (params?: any) => void;
  /** 获取当前最新数据 */
  getCurrentInfo?: (params?: any) => void;
  placeholder?: string;
  /** 是否自动请求数据 */
  autoAcquisition?: boolean;
  /** 是否支持删除 */
  canDelete?: boolean;
  /** 是否支持编辑 */
  canEdit?: boolean;
  /** 是否可新增 */
  canCreate?: boolean;
  value?: string | number;
  /** 是否隐藏展示说明 */
  hiddenDesc?: Boolean;
  /** 是否隐藏select(主要用于详情显示) */
  hiddenSelect?: Boolean;
  style?: CSSProperties;
  /** 账户信息快照，回显用，逻辑：cardlist接口回来后，比对传入的快照数据，如果有，则正常；无，则说明该账户信息已被删除，为了能正常显示，将快照数据join进options，并disabled */
  snapShot?: any;
}
export interface SelectAccountInfoRef extends SelectProps {
  checkInfo: any;
}
const cardReg = /(\d{4})(?=\d)/g;
const SelectAccountInfo = (props: SelectAccountInfoProps, ref: any) => {
  const {
    placeholder = '请选择',
    autoAcquisition = true,
    value,
    onChange,
    listServer,
    snapShot,
    canEdit = true,
    canCreate = true,
    hiddenDesc = false,
    hiddenSelect = false,
    createAccountServer,
    getAccountInfoServer,
    editAccountServer,
    getCurrentInfo,
    getCityServer,
    style,
    ...reset
  } = props;
  const { currencyLabel } = useCurrencyCodeAndSymbol();
  const [open, setOpen] = useState<boolean>(false);
  const [actionType, setActionType] = useState<{ open: boolean; actionType?: Action_Type }>({ open: false });
  const [checkInfo, setCheckInfo] = useState<any>();
  const [accountInfo, setAccountInfo] = useState<any>();

  const { data, loading, refresh } = useRequest(listServer, { manual: !autoAcquisition });
  const { bankCards = [] } = data ?? {};

  useEffect(() => {
    const targetInfo = bankCards?.find((item: any) => item.bankCardId === value);
    setCheckInfo(targetInfo);
    getCurrentInfo?.(targetInfo);
  }, [value, bankCards, getCurrentInfo]);

  useImperativeHandle(
    ref,
    () => ({
      checkInfo,
    }),
    [checkInfo]
  );

  /**
   * @method 浮层展示/隐藏
   * @param open
   * @returns
   */
  const onDropdownVisibleChange = (open: boolean) => setOpen(open);

  /**
   * @method 数据选中
   * @param info
   */
  const handleCheck = (info: any) => {
    onChange?.(info?.bankCardId);
    setOpen(false);
    setCheckInfo(info);
    getCurrentInfo?.(info);
  };

  /**
   * @method 清除操作
   */
  const handleClear = () => {
    setCheckInfo(undefined);
    getCurrentInfo?.(null);
    onChange?.();
  };

  /**
   * @method 新增账户
   */
  const handleCreateAccount = () => {
    setAccountInfo(undefined);
    setActionType({ open: true, actionType: Action_Type.CREATE });
  };

  /**
   * @method 编辑账户
   * @param info
   */
  const handleEditAccount = (e: any, info: any) => {
    e.stopPropagation();
    setAccountInfo(info);
    getCurrentInfo?.(info);
    setActionType({ open: true, actionType: Action_Type.EDIT });
  };

  /**
   * @method 确认添加
   */
  const handleConfirm = (id: string | number, formInfo: any) => {
    const targetInfo = { ...formInfo, coin: CurrencyTrans[formInfo?.coin as keyof typeof CurrencyTrans] };
    setCheckInfo(targetInfo);
    getCurrentInfo?.(targetInfo);
    id && onChange?.(id);
    setActionType({ open: false });
    refresh?.();
  };

  /**
   * @method 自定义下拉浮层
   * @returns
   */
  const dropdownRender = () => {
    return (
      <>
        <List
          itemLayout="horizontal"
          dataSource={bankCards}
          renderItem={(item: any) => (
            <div className={cls(styles.itemContainer, { [styles.checked]: item.bankCardId === value })} onClick={() => handleCheck(item)}>
              <Space className={styles.itemInfo} direction="vertical" size={4}>
                <span className={styles.title}>{item.enterpriseName}</span>
                <div className={styles.descInfo}>{`银行账号：${item.cardNo?.replace(cardReg, '$1 ') || '-'}（${
                  currencyLabel[item?.coin as keyof typeof currencyLabel]
                }）`}</div>
              </Space>

              <Auth auth={canEdit}>
                {item?.certificationStatus === 'MEMBER_AUTH_STATUS_VERIFIED' ? (
                  <Tooltip title="可通过此卡进行账户充值及提现">
                    <span className={styles.cardTag}>已认证</span>
                  </Tooltip>
                ) : (
                  <IconFont type="easygo-icon-Edit" className={styles.action} onClick={(e) => handleEditAccount(e, item)} />
                )}
              </Auth>
            </div>
          )}
          className={styles.listContainer}
          locale={{
            emptyText: (
              <div style={{ paddingTop: 12 }}>
                <Empty
                  imageStyle={{ marginBottom: 12 }}
                  image={
                    <Image preview={false} src={'//yichang-fronted-public.oss-cn-hangzhou.aliyuncs.com/imgs/product-empty.svg'} alt="暂无数据" />
                  }
                  description={'暂无数据'}
                />
              </div>
            ),
          }}
        />
        <Auth auth={canCreate && bankCards?.length < 4}>
          <div className={styles.actionContainer} onClick={handleCreateAccount}>
            <IconFont type="easygo-icon-Add" className={styles.icon} />
            新增账户
          </div>
        </Auth>
      </>
    );
  };

  /**
   * @method 格式化源数据
   * @returns
   */
  const formatOptions = () => {
    let res = bankCards.map((item: any) => ({
      label: `${item.enterpriseName} - ${item.cardNo} (${currencyLabel[item.coin as keyof typeof currencyLabel]})`,
      value: item.bankCardId,
    }));
    if (snapShot) {
      const cur = res.find((item) => item.value === snapShot.bankCardId);
      if (!cur && res && snapShot && snapShot.bankCardId) {
        res.unshift({
          label: `${snapShot?.enterpriseName} - ${snapShot?.cardNo} (${currencyLabel[snapShot?.coin as keyof typeof currencyLabel]})`,
          value: snapShot?.bankCardId,
          disabled: true,
        });
      }
    }
    return res;
  };

  return (
    <>
      <Select
        open={open}
        value={value}
        loading={loading}
        onChange={onChange}
        onClear={handleClear}
        options={formatOptions()}
        placeholder={placeholder}
        dropdownStyle={{ zIndex: 1000 }}
        dropdownRender={dropdownRender}
        popupClassName={styles.dropContainer}
        onDropdownVisibleChange={onDropdownVisibleChange}
        getPopupContainer={(node) => node?.parentElement}
        style={{ ...(style || {}), display: hiddenSelect ? 'none' : 'block' }}
        {...reset}
      />
      <Auth auth={!hiddenDesc && !!checkInfo}>
        <Descriptions className={styles.accountInfo} column={1}>
          <Descriptions.Item label="账户名称">{checkInfo?.enterpriseName ?? '-'}</Descriptions.Item>
          <Descriptions.Item label="开户行">{`${checkInfo?.bankName ?? ''} - ${checkInfo?.subBankName ?? ''}`}</Descriptions.Item>
          <Descriptions.Item label="银行账号">{`${checkInfo?.cardNo ?? '-'} (${
            currencyLabel[checkInfo?.coin as keyof typeof currencyLabel]
          })`}</Descriptions.Item>
        </Descriptions>
      </Auth>
      <AccountModal
        onOk={handleConfirm}
        open={actionType?.open}
        onCancel={() => setActionType({ open: false })}
        confirmAccountServer={createAccountServer!}
        getAccountInfoServer={getAccountInfoServer!}
        editAccountServer={editAccountServer!}
        getCityServer={getCityServer!}
        title={Action_Type_Label[actionType?.actionType as keyof typeof Action_Type_Label]}
        bankCardId={accountInfo?.bankCardId}
        actionType={actionType?.actionType!}
      />
    </>
  );
};
export default forwardRef<SelectAccountInfoRef, SelectAccountInfoProps>(SelectAccountInfo);
