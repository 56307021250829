import { useControllableValue } from 'ahooks';
import { Input, Space } from 'antd';
import type { InputProps } from 'antd';

import styles from './index.module.less';

export const RateInput: React.FC<InputProps> = ({ prefix, suffix, ...rest }) => {
  const [value, onChange] = useControllableValue(rest);

  const inputProps = { ...rest, value, onChange };

  return (
    <Space className={styles.wrapper} wrap={false}>
      <span className={styles.span}>{prefix}</span>
      <Input {...inputProps} />
      <span className={styles.span}>{suffix}</span>
    </Space>
  );
};
