import { i18n } from '@easygo/i18n';
import type { FC } from 'react';
import { Collapse, Table } from 'antd';
import dayjs from 'dayjs';
import type { ColumnsType } from 'antd/es/table';
import styles from './index.module.less';
import type { feeInfo } from '../utils';
import { formatAmount, formatByCoinType, formatFeeAmount } from '../utils';
import BigNumber from 'bignumber.js';
import { useRequest } from 'ahooks';
import { IconFont, useDict } from '@easygo/components';
import type { boxTypeItem } from '../boxType';
import { computeBaseAmount } from '../orderAmount';
import { useFormatAmount } from '../../../../hooks';
import { useSimleMutipleCurrencyAmountFormator } from '@easygo/components/src/hooks/useMultipleCurrencyAmount';

interface PriceInformationProps {
  baseFee: boxTypeItem[];
  extraFees: feeInfo[];
  quantity: number | string;
  rateServer?: any;
  hiddenPrice?: boolean;
}
const { Panel } = Collapse;
const PriceInformation: FC<PriceInformationProps> = (props) => {
  const { baseFee = [], extraFees = [], hiddenPrice, rateServer } = props ?? {};
  const [boxTypeDict] = useDict('boxType');
  const extraFeesBoxMap: Record<string, any> = {};
  // const { data: rateInfo } = useRequest(rateServer, {
  //   manual: false,
  //   defaultParams: [{ base: 'CURRENCY_TYPE_DOLLAR', rmb: 'CURRENCY_TYPE_RMB' }],
  // });
  extraFees.forEach((feeInfo) => {
    const surchargeEntryName = feeInfo.surchargeEntryName;
    if (!extraFeesBoxMap[surchargeEntryName]) {
      extraFeesBoxMap[surchargeEntryName] = [feeInfo];
    } else {
      extraFeesBoxMap[surchargeEntryName].push(feeInfo);
    }
  });

  const formator = useSimleMutipleCurrencyAmountFormator();
  const formatAmount = useFormatAmount();
  // 基本费用
  const baseItems = baseFee.map((item) => ({ currency: item.currentType!, amount: BigNumber(item.price).times(item.planCount).toNumber() }));
  const baseFeeStr = formator(baseItems);
  // 附件费用
  const extraFeeStr = formator(extraFees.map((item) => ({ currency: item.currency, amount: item.price })) as any);

  const boxColumns = baseFee?.map(({ boxId }) => ({
    key: boxId,
    dataIndex: boxId,
    title: boxTypeDict?.find((boxItem: any) => boxItem.id === boxId)?.label,
    render: (_, record: any) => {
      const boxMap = extraFeesBoxMap?.[record?.surchargeEntryName];
      const target = boxMap?.find((item) => item.boxId === boxId);
      return target?.unitPrice ? `${formatAmount(target?.unitPrice, target?.currency)} * ${target?.planCount}` : '-';
    },
  }));

  const columnsBase: ColumnsType<[]> = [
    {
      title: i18n?.t('箱型'),
      dataIndex: 'boxId',
      width: 350,
      render: (value: string) => boxTypeDict?.find((boxItem: any) => boxItem.id === value)?.label,
    },
    {
      title: i18n?.t('单价'),
      dataIndex: 'price',
      render: (value: any, record: any) => <span className={styles.textStyle}>{formatAmount(BigNumber(value).toNumber(), record?.currentType)}</span>,
      width: 280,
    },
    {
      title: i18n?.t('数量'),
      dataIndex: 'planCount',
      width: 280,
    },
    {
      title: i18n?.t('金额'),
      dataIndex: 'amount',
      render: (_, record: any) => (
        <span className={styles.textStyle}>{formatAmount(BigNumber(record?.price).times(record?.planCount).toNumber(), record?.currentType)}</span>
      ),

      width: 280,
    },
  ];

  const columnsExtra: ColumnsType<[]> = [
    {
      title: i18n?.t('费用明细'),
      dataIndex: 'surchargeEntryName',
      key: 'surchargeEntryName',
    },
    ...boxColumns,
    {
      title: i18n?.t('每票'),
      dataIndex: 'BL',
      key: 'BL',
      render: (_, record: any) => {
        const boxMap = extraFeesBoxMap?.[record?.surchargeEntryName];
        const target = boxMap?.filter((item) => !item.boxId);
        return formatAmount(target?.[0]?.price, target?.[0]?.currency);
      },
    },
  ];

  return (
    <div className={styles.orderPriceContainer}>
      <Collapse defaultActiveKey={['base', 'extra']} expandIconPosition="end" ghost>
        <Panel header={i18n?.t('海运费')} key="base" extra={<span className={styles.numPrice}>{baseFeeStr || 0}</span>}>
          <Table columns={columnsBase} dataSource={baseFee as []} pagination={{ hideOnSinglePage: true }} rowKey="skuId" />
        </Panel>
        <Panel
          header={i18n?.t('附加费')}
          key="extra"
          extra={<span className={styles.numPrice}>{hiddenPrice ? '0' : /-/.test(extraFeeStr) ? 0 : extraFeeStr}</span>}
        >
          <Table
            columns={columnsExtra}
            dataSource={hiddenPrice ? [] : (Object.keys(extraFeesBoxMap)?.map((item) => ({ surchargeEntryName: item })) as [])}
            pagination={{ hideOnSinglePage: true }}
            rowKey="feeId"
          />
        </Panel>
      </Collapse>
      {/* <div className={styles.footer}>
        <div className={styles.info}>
          <div className={styles.warning}>
            <IconFont type="easygo-icon-warning-triangle" />
          </div>
          {i18n?.t('1美元 =')}
          {rateInfo?.exchangeRate}
          {i18n?.t(
            '人民币           -----汇率仅供参考，请以最终账单为准，海运费锁定后，时价、附加费、增值服务费根据委托的实际情况可能会有所增减，以客服与您确认的为准。'
          )}
        </div>
        <div className={styles.amountWrap}>
          {i18n?.t('费用总计：')}
          <span className={styles.amount}>{formatFeeAmount(formatByCoinType([computeBaseAmount(baseFee), ...extraFees]))}</span>
        </div>
      </div> */}
    </div>
  );
};
export default PriceInformation;
