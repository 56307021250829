import { i18n } from '@easygo/i18n';
import styles from './index.module.less';
const CrossPage = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{i18n?.t('更多跨境卖家的发货首选')}</div>
      <div className={styles.center}></div>
    </div>
  );
};
export default CrossPage;
