import { EasyModal, ProTable, ProTitle, useDict } from '@easygo/components';
import styles from './index.module.less';
import { useRequest } from 'ahooks';
import { columns, feeColumns } from './fields';
import { Button, Checkbox, Table, Tooltip } from 'antd';
import { useMemo, useState } from 'react';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import type { CheckboxValueType } from 'antd/lib/checkbox/Group';
import type { API } from '@easygo/service/src/apis/api/typings';
import type { CurrencySymbolMap } from '@easygo/utils/src/enums';
import { CurrencyMap } from '@easygo/utils/src/enums';
import classNames from 'classnames/bind';
import BigNumber from 'bignumber.js';
import { downloadFile } from '@easygo/utils/src/utils';
import dayjs from 'dayjs';
import { useCurrencyCodeAndSymbol, useSimleFormatMutipleCurrencyAmount } from '@easygo/components/src/hooks/useMultipleCurrencyAmount';
const cx = classNames.bind(styles);

interface Props {
  open: boolean;
  onClose: () => void;
  onOk: (billData?: any) => Promise<any>;
  mainBill: any;
  subList: API.SubStatementItemVO[];
  cardsRequest: (args: any) => Promise<API.MemberBankCardResponseVO>;
  feeRequest: (args: any) => Promise<any>;
  exportRequest: (...args: any) => Promise<any>;
}
/** 确认账单 */
export default function BillDetailModal({ cardsRequest, feeRequest, exportRequest, subList, open, onClose, onOk, mainBill }: Props) {
  const { currencySymbol } = useCurrencyCodeAndSymbol();
  const plainOptions = subList?.map(({ orderNo, orderId, subStatementId }) => ({ orderNo, orderId, subStatementId }));
  //orderId数组 默认选中第一个订单
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([subList?.[0]?.orderId]);
  const [indeterminate, setIndeterminate] = useState(subList?.length !== 1);
  const [checkAll, setCheckAll] = useState(subList?.length === 1);
  const [chooseSonBill, setChooseSonBill] = useState(subList?.[0]?.orderId);
  const [boxType] = useDict('boxType');

  const { loading: okLoading, run: runOk } = useRequest(onOk, { manual: true });
  const { loading: exportLoading, runAsync: runExport } = useRequest(exportRequest, { manual: true });

  const { data: cardsData, loading: cardsLoading } = useRequest(cardsRequest, {
    defaultParams: [{ enterpriseId: mainBill.payeeId }],
  });
  const {
    runAsync: getFee,
    data: feeData,
    loading: feeLoading,
  } = useRequest(feeRequest, {
    defaultParams: [{ orderId: chooseSonBill, statementId: mainBill?.statementId, subStatementId: subList?.[0]?.subStatementId }],
  });

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions?.map((item) => item.orderId) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  const onChange = (value, e) => {
    let list = checkedList;
    if (e.target.checked) {
      list.push(value.orderId);
    } else {
      list = list.filter((data) => data !== value.orderId);
    }

    setCheckedList([...list]);
    setCheckAll(list.length === plainOptions.length);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
  };
  const totalAmount = useSimleFormatMutipleCurrencyAmount(
    feeData?.map((item) => ({
      currency: item.currentType as unknown as keyof typeof CurrencySymbolMap,
      amount: BigNumber(item.price!).times(item.count!).toNumber(),
    })) || []
  );
  const accountData = useMemo(() => {
    const data: any = [];
    const dollor = cardsData?.bankCards?.find((item) => item?.coin === CurrencyMap.SKU_COIN_TYPE_DOLLAR);
    const rmb = cardsData?.bankCards?.find((item) => item?.coin === CurrencyMap.SKU_COIN_TYPE_RMB);
    rmb && data.push({ type: '人民币账户', ...rmb, subBankName: rmb.bankName + rmb.subBankName });
    dollor && data.push({ type: '美元账户', ...dollor, subBankName: dollor.bankName + dollor.subBankName });
    return data;
  }, [cardsData]);

  const handleExport = async () => {
    const content = await runExport(
      {
        statementId: mainBill?.statementId,
        subStatementIds: checkedList?.map((item) => plainOptions?.find((option) => option?.orderId === item)?.subStatementId).join(','),
      },
      {
        responseType: 'blob',
      }
    );
    downloadFile(
      content,
      checkedList?.length === 1
        ? `海运${plainOptions?.find((item) => item.orderId === checkedList?.[0])?.orderNo}`
        : `海运账单${dayjs().format('YYYYMMDD')}`,
      checkedList?.length === 1 ? 'application/pdf' : 'application/zip'
    );
  };
  return (
    <EasyModal
      title="账单详情"
      visible={open}
      width={960}
      handleCancel={onClose}
      wrapClassName={styles.easyModal}
      onOk={() => runOk({})}
      confirmLoading={okLoading}
      footer={null}
    >
      <ProTable pagination={false} dataSource={[mainBill]} locale={{ emptyText: <></> }} columns={columns}></ProTable>
      <div className={styles.btnWrap}>
        {checkedList?.length ? (
          <Button loading={exportLoading} type="primary" onClick={handleExport}>
            导出账单明细
          </Button>
        ) : (
          <Tooltip title="请选择要导出的订单">
            <Button disabled type="primary">
              导出账单明细
            </Button>
          </Tooltip>
        )}
      </div>
      <div className={styles.sonBill}>
        <div className={styles.left}>
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            订单号
          </Checkbox>
          {plainOptions?.map((item) => (
            <div
              className={cx('checkbox', { choosed: chooseSonBill === item.orderId })}
              key={item.orderId}
              onClick={() => {
                if (item.orderId !== chooseSonBill) {
                  getFee({ orderId: item.orderId, statementId: mainBill?.statementId, subStatementId: item?.subStatementId });
                  setChooseSonBill(item.orderId);
                }
              }}
            >
              <Checkbox
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => onChange(item, e)}
                checked={checkedList.includes(item.orderId)}
              ></Checkbox>
              <span className={styles.text}>{item.orderNo}</span>
            </div>
          ))}
        </div>
        <div className={styles.right}>
          <div className={styles.title}>费用明细</div>
          <div className={styles.summary}>金额汇总：{totalAmount}</div>
          <Table
            loading={feeLoading}
            pagination={false}
            dataSource={feeData}
            locale={{ emptyText: <>暂无数据</> }}
            columns={feeColumns(boxType, currencySymbol)}
          ></Table>
        </div>
      </div>
      <div className={styles.accountWrap} style={{ marginTop: 48, marginBottom: 24 }}>
        <ProTitle>回款账户信息</ProTitle>
        <Table
          bordered
          loading={cardsLoading}
          title={() => `${mainBill.payeeName}`}
          dataSource={accountData}
          pagination={false}
          columns={[
            {
              dataIndex: 'type',
              width: 120,
            },
            {
              title: '账号',
              dataIndex: 'cardNo',
              width: 300,
              align: 'center',
            },
            {
              title: '开户银行',
              dataIndex: 'subBankName',
              width: 300,
              align: 'center',
            },
          ]}
        ></Table>
      </div>
    </EasyModal>
  );
}
