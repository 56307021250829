import type { API } from '@easygo/service/src/apis';
import React from 'react';

export interface ConfigApisContextType {
  pageBoxType: (params: API.OperationIdSalesgatewayConfigCenterControllerPageBoxTypeParams) => Promise<API.IPageBoxTypeDTO>;
  pageAddress: (params: API.OperationIdSalesgatewayConfigCenterControllerPageAddressParams) => Promise<API.IPageAddressDTO>;
  recommendRequest?: any;
}

export const ConfigApisContext = React.createContext<ConfigApisContextType | undefined>(undefined);
