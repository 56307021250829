export enum COMPONENT_TYPE {
  CITY_OR_PORT = 'CITY_OR_PORT',
  BOX_TYPE = 'BOX_TYPE',
  RANGE_PICKER = 'RANGE_PICKER',
}

export enum AreaType {
  CITY = 'CITY',
  STATION = 'STATION',
  PORT = 'PORT',
}
