import styles from './index.module.less';
import { EasyModal } from '@easygo/components';
import { Form, Input, Spin, InputNumber, Radio, Table } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useMemo, useState } from 'react';
import { CurrencySymbolMap, CurrencyTypeMap } from '@easygo/utils/src/enums';
import { multipleCurrencyAmountToDisplay } from '@easygo/components/src/hooks/useMultipleCurrencyAmountToDisplay';
import { useRequest } from 'ahooks';
import { billColumns } from './fields';
import classNames from 'classnames';
import BigNumber from 'bignumber.js';
import { PlusOutlined } from '@ant-design/icons';
import { Settlement_Status } from '../../../../constants/oceanBill';

function canSubStamentEdit(substatement: { statementStatus: Settlement_Status }) {
  return substatement.statementStatus === Settlement_Status.STATEMENT_STATUS_WAITING_CONFIRM;
}

export interface EditBillModalProps {
  performanceId: string;
  orderId: string;
  subStatementId?: string;
  open: boolean;
  onClose: () => void;
  onOk: (payload: any) => void;
  statementApi: (...args: any) => Promise<any>;
}
const EditBillModal: React.FC<EditBillModalProps> = ({ performanceId, orderId, subStatementId, statementApi, open, onClose, onOk }) => {
  const [form] = useForm();

  const [selectedSubStament, setSelectedSubStament] = useState<any>();

  useEffect(() => {
    if (!selectedSubStament) {
      return;
    }

    form.setFieldsValue({ rate: selectedSubStament.rate, remark: selectedSubStament.remark });
  }, [form, selectedSubStament]);

  const hasSelected = useMemo(() => !!selectedSubStament, [selectedSubStament]);

  const { data: statement, loading } = useRequest(() => statementApi({ performanceId }), {
    ready: !!performanceId,
    onSuccess: (statement) => {
      if (subStatementId) {
        const targetStatement = (statement?.subStatementItemVOS || []).find(
          (item: any) => item.subStatementId === subStatementId && canSubStamentEdit(item)
        );
        if (targetStatement) {
          setSelectedSubStament(targetStatement);
        }
      } else {
        const unpaySubstaments = (statement?.subStatementItemVOS || []).filter((item: any) => canSubStamentEdit(item));
        if (unpaySubstaments.length === 1) {
          setSelectedSubStament(unpaySubstaments[0]);
        }
      }
    },
  });

  const statementAmountToDisplay = useMemo(() => {
    if (!statement) {
      return '';
    }

    return multipleCurrencyAmountToDisplay([
      { currency: CurrencyTypeMap.DOLLAR, amount: statement.dollarAmount || 0 },
      { currency: CurrencyTypeMap.RMB, amount: statement.rmbAmount || 0 },
    ]);
  }, [statement]);

  const outAmountToDisplay = useMemo(() => {
    if (!statement) {
      return '';
    }

    return multipleCurrencyAmountToDisplay([
      { currency: CurrencyTypeMap.DOLLAR, amount: statement.outDollarAmount || 0 },
      { currency: CurrencyTypeMap.RMB, amount: statement.outRmbAmount || 0 },
    ]);
  }, [statement]);

  const outAvailableDollarAmount = useMemo(() => {
    if (!statement || !selectedSubStament) {
      return 0;
    }

    const amountLeft = BigNumber(statement.dollarAmount || 0)
      .plus(selectedSubStament.dollarAmount || 0)
      .minus(statement.outDollarAmount || 0)
      .toNumber();

    return amountLeft > 0 ? amountLeft : 0;
  }, [statement, selectedSubStament]);

  const outAvailableRmbAmount = useMemo(() => {
    if (!statement || !selectedSubStament) {
      return 0;
    }

    const amountLeft = BigNumber(statement.rmbAmount || 0)
      .plus(selectedSubStament.rmbAmount || 0)
      .minus(statement.outRmbAmount || 0)
      .toNumber();

    return amountLeft > 0 ? amountLeft : 0;
  }, [statement, selectedSubStament]);

  const outAvailableAmountToDisplay = useMemo(() => {
    return multipleCurrencyAmountToDisplay([
      { currency: CurrencyTypeMap.DOLLAR, amount: outAvailableDollarAmount },
      { currency: CurrencyTypeMap.RMB, amount: outAvailableRmbAmount },
    ]);
  }, [outAvailableDollarAmount, outAvailableRmbAmount]);

  const canEdit = useMemo(
    () => hasSelected && (outAvailableDollarAmount > 0 || outAvailableRmbAmount > 0),
    [hasSelected, outAvailableDollarAmount, outAvailableRmbAmount]
  );

  useEffect(() => {
    if (!selectedSubStament) {
      return;
    }

    form.setFieldsValue({
      dollarAmount: Number(selectedSubStament.dollarAmount) > 0 ? Number(selectedSubStament.dollarAmount) : undefined,
      rmbAmount: Number(selectedSubStament.rmbAmount) > 0 ? Number(selectedSubStament.rmbAmount) : undefined,
    });
  }, [form, selectedSubStament]);

  const [submitting, setSubmitting] = useState(false);

  const submit = () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
    }, 500);

    form
      .validateFields()
      .then(({ rmbAmount, dollarAmount, rate, remark }) => {
        onOk({
          items: [
            {
              subStatementId: selectedSubStament.subStatementId,
              orderId,
              rmbAmount: rmbAmount || 0,
              dollarAmount: dollarAmount || 0,
              rate,
              remark,
            },
          ],
        });
      })
      .catch(() => {});
  };

  const amountValidator = (_: any, value: any) => {
    const { rmbAmount, dollarAmount } = form.getFieldsValue();
    if (!rmbAmount && !dollarAmount) {
      return Promise.reject(new Error('请输入金额'));
    }

    return Promise.resolve();
  };

  return (
    <EasyModal
      width={640}
      title={'修改账单'}
      wrapClassName={styles['invoice-upload-modal']}
      bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
      maskClosable={false}
      visible={open}
      handleCancel={onClose}
      onOk={submit}
      okButtonProps={{ disabled: loading || !canEdit, loading: submitting }}
    >
      <Spin spinning={loading}>
        <div className={styles['payment-info']}>
          <div className={styles.item}>
            <div className={styles.title}>订单号</div>
            <div className={styles.value}>{statement?.orderNo}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>订单总金额</div>
            <div className={classNames(styles.value, styles.emphasize)}>{statementAmountToDisplay}</div>
            <div className={styles.extra}>已出账单金额：{outAmountToDisplay}</div>
          </div>
          {(statement?.subStatementItemVOS || []).length > 0 && (
            <div className={styles.item}>
              <div className={styles.title}>选择要修改的账单</div>
              <div className={styles.value}>
                <Table
                  rowKey="statementId"
                  columns={billColumns}
                  dataSource={statement?.subStatementItemVOS || []}
                  pagination={false}
                  rowSelection={{
                    columnTitle: '账单编号',
                    columnWidth: 136,
                    renderCell: (checked, record, index, originNode) => (
                      <Radio
                        disabled={!canSubStamentEdit(record)}
                        value={record.statementId}
                        checked={selectedSubStament === record}
                        onChange={() => setSelectedSubStament(record)}
                      >
                        {record.subStatementNo}
                      </Radio>
                    ),
                  }}
                />
              </div>
            </div>
          )}
          {hasSelected && (
            <div className={styles.item}>
              <div className={styles.title}>可出账单金额</div>
              <div className={classNames(styles.value, styles.emphasize)}>{outAvailableAmountToDisplay}</div>
              <div className={classNames(styles.extra, styles.warn)}>
                {canEdit ? '若金额需要调整，请修改订单费用后再出账单' : '当前订单金额已全部出账，当前无可出账金额，请核实'}
              </div>
            </div>
          )}
        </div>

        {canEdit && (
          <Form form={form} onFinish={onOk} layout="vertical">
            <Form.Item className={styles['price-form-item']} label={'本次出账金额'} required>
              <div className={styles.prices}>
                {outAvailableDollarAmount > 0 && (
                  <Form.Item name="dollarAmount" rules={[{ validator: amountValidator }]}>
                    <InputNumber
                      style={{ width: '100%' }}
                      addonBefore={CurrencySymbolMap[CurrencyTypeMap.DOLLAR]}
                      precision={2}
                      min={0.01}
                      max={outAvailableDollarAmount}
                      onChange={() => form.validateFields(['rmbAmount'])}
                    />
                  </Form.Item>
                )}
                {outAvailableDollarAmount > 0 && outAvailableRmbAmount > 0 && <PlusOutlined style={{ marginBottom: 24 }} />}
                {outAvailableRmbAmount > 0 && (
                  <Form.Item name="rmbAmount" rules={[{ validator: amountValidator }]}>
                    <InputNumber
                      style={{ width: '100%' }}
                      addonBefore={CurrencySymbolMap[CurrencyTypeMap.RMB]}
                      precision={2}
                      min={0.01}
                      max={outAvailableRmbAmount}
                      onChange={() => form.validateFields(['dollarAmount'])}
                    />
                  </Form.Item>
                )}
              </div>
            </Form.Item>
            <Form.Item name="rate" label={'美元汇率'} rules={[{ required: true }]}>
              <InputNumber style={{ width: '100%' }} addonBefore={'1美元='} precision={4} min={0.0001} max={99.9999} />
            </Form.Item>
            <Form.Item name="remark" label={'账单说明'}>
              <Input.TextArea rows={3} maxLength={200}></Input.TextArea>
            </Form.Item>
          </Form>
        )}
      </Spin>
    </EasyModal>
  );
};

export default EditBillModal;
