import { IconFont, useGlobalState } from '@easygo/components';
import type { API } from '@easygo/service/src/apis';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { useState } from 'react';
import { bussinessIcon, MessageStatus, typeIcon, typeLabel } from './constant';
import styles from './index.module.less';

const cx = classNames.bind(styles);

const MessageItem: React.FC<{
  item: API.MessageVo;
  categoryList: any[];
  status: keyof typeof MessageStatus;
  handleConfirm: (...args: any) => void;
  t: any;
}> = ({ item, categoryList = [], status, handleConfirm, t }) => {
  const {
    state: { currState },
  } = useGlobalState();
  const { level, title, content, categoryId, createdTime, type, uri, messageId } = item;
  const [isFold, setIsFold] = useState(true);
  const handleSolve = () => {
    handleConfirm({ messageId });

    /***
     消息中心的消息路由可能是卖家地址或买家地址，
     但当前用户只有当前身份的路由权限（买家或卖家），
     需做路由替换，替换为当前身份的路由
   */

    if (uri) {
      let realUri = uri.replace(/(\/buier\/|\/saler\/)/, `/${currState}/`);
      if (!realUri.includes(`/manager`)) {
        if (realUri.startsWith('/')) {
          realUri = `/manager${realUri}`;
        } else {
          const url = new URL(realUri);
          realUri = realUri.replace(url.origin, `${url.origin}/manager`);
        }
      }
      window.open(realUri);
    }
  };
  return (
    <div className={cx('itemWrapper', { solved: status !== MessageStatus.MESSAGE_STATUS_UNPROCESSED })}>
      <div className={styles.lineTitlt}>
        <span className={styles.flex}>
          {/* 业务类型根据字典ID取后端返回文案 */}
          <span className={styles.businesss}>
            <span className={styles.iconWrapper}>
              <IconFont type={bussinessIcon[categoryId as keyof typeof bussinessIcon] || typeIcon[type as keyof typeof typeIcon]} />
            </span>
            {categoryList.find((item) => item.id === categoryId)?.data || typeLabel[type as keyof typeof typeLabel]}
          </span>
          <span className={styles.titleText}>{title}</span>
        </span>

        <span className={styles.time}>{dayjs(createdTime).format('YYYY-MM-DD HH:mm:ss')}</span>
      </div>
      <div className={styles.lineDesc}>
        <span className={cx('type', `${level}`)}>{t(level, { ns: 'enums' })}</span>
        <Tooltip title={content}>
          <span className={cx('contentText', `${status}`, { isFold })}>{content}</span>
        </Tooltip>
        {status === MessageStatus.MESSAGE_STATUS_UNPROCESSED && (
          <Button type="primary" className={cx('operation_btn')} onClick={handleSolve}>
            {t(uri ? 'deal' : 'read', { ns: 'cashier' })}
          </Button>
        )}
      </div>
    </div>
  );
};
export default MessageItem;
