import { i18n } from '@easygo/i18n';
import { EasyTabs, IconFont, ProPagination, useGlobalState } from '@easygo/components';
import { Button, Drawer, Empty, List, message, Select, Space } from 'antd';
import styles from './index.module.less';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useRequest, useDebounceFn } from 'ahooks';
import { MESSAGE_TYPE, MessageLevel, MessageStatus, MESSAGE_TYPE_OBJ } from './constant';
import MessageItem from './MessageItem';
import type { API } from '@easygo/service/src/apis/api/typings';
import { getEnumsLabelValue } from '@easygo/utils/src/utils';
import { useSubscript } from '../../constants/events';
import { EasyModal, ProSelect } from '@easygo/components';
import { sharedApiConfig } from '@easygo/config/commonApiConfig';
export type BoxRef = { toShow: (...args: any[]) => void } | undefined;
const MessageBox = forwardRef<any, BoxRef>((props, ref) => {
  const { t, confirmMessageReq, queryMessageReq, unprocessedCountReq } = props;
  const [open, setOpen] = useState(false);
  const [searchParam, setSearchParam] = useState<API.MessageListReqVO>({
    page: 1,
    size: 10,
    status: MessageStatus.MESSAGE_STATUS_UNPROCESSED,
    level: MessageLevel.ALL,
  });
  const { runAsync: confirmMessage } = useRequest(confirmMessageReq, {
    manual: true,
  });
  const {
    loading,
    run: queryMessage,
    data = { messageList: [], categoryList: [] },
  } = useRequest(queryMessageReq, {
    manual: true,
    onBefore(params) {
      if (params && params[0] && params[0].categoryId && !Array.isArray(params[0].categoryId)) {
        params[0].categoryId = [params[0].categoryId];
      }
    },
  });
  const {
    state: { session },
  } = useGlobalState();

  const getUndealMessage = useRequest(unprocessedCountReq, {
    manual: !session.accessToken,
  });
  const handleConfirm = async (params: any) => {
    if (params.level === MessageLevel.ALL) {
      delete params.level;
    }
    await confirmMessage(params);
    message.success(t('message_success', { ns: 'auth' }));
    const messageParam = { ...searchParam };
    if (messageParam.level === MessageLevel.ALL) delete messageParam.level;
    await queryMessage(messageParam);

    getUndealMessage.run();
  };
  useEffect(() => {
    const param = {
      ...searchParam,
    };
    if (param.level === MessageLevel.ALL) delete param.level;
    if (session.accessToken) queryMessage(param);
  }, [queryMessage, searchParam]);
  const paginationProps = {
    current: data?.page,
    total: data?.total,
    showQuickJumper: false,
    showTotal: () => null,
    onChange: (page: number) => {
      setSearchParam({ ...searchParam, page });
    },
  };

  const { run: handleRead } = useDebounceFn(
    () => {
      handleConfirm({ type: searchParam.type, level: searchParam.level });
    },
    { wait: 300 }
  );

  // 业务类型下拉
  const { data: categoryArr } = useRequest(sharedApiConfig.apiConfig.getBusinessType);
  const categoryArrList = categoryArr?.map((it) => ({ label: it.data, value: it.id })) || [];

  const messageContent = useMemo(() => {
    return (
      <>
        <div className={styles.contentHeader}>
          <Space>
            <Select
              value={searchParam.level}
              onChange={(value) => handleLevelChange(value)}
              options={getEnumsLabelValue(MessageLevel, t, { ns: 'enums' })}
            ></Select>
            <Select
              value={searchParam.categoryId}
              onChange={(categoryId: string) => paramsChangeHandler({ categoryId })}
              options={categoryArrList}
              style={{ width: '100px' }}
              placeholder={i18n?.t('业务类型')}
              allowClear
            ></Select>
          </Space>
          {searchParam.status === MessageStatus.MESSAGE_STATUS_UNPROCESSED && (
            <Button type="primary" onClick={handleRead}>
              {t('message_batch', { ns: 'cashier' })}
            </Button>
          )}
        </div>

        <List
          loading={loading}
          dataSource={data?.messageList}
          locale={{ emptyText: <Empty description={t('MESSAGE_EMPTY', { ns: 'message' })} image={'/images/status/message-empty.svg'}></Empty> }}
          renderItem={(item) => {
            const itemProps = { t, item, categoryList: data?.categoryList, type: searchParam.type, status: searchParam.status, handleConfirm };
            return <MessageItem {...itemProps} />;
          }}
        />
      </>
    );
  }, [data, loading, searchParam]);
  useImperativeHandle(ref, () => ({
    toShow() {
      setOpen(true);
    },
  }));

  const onClose = () => {
    setOpen(false);
    props?.refreshCount && props.refreshCount();
  };
  const labelNode = (item: { value: string }) => {
    const num = (getUndealMessage?.data as any)?.[MESSAGE_TYPE_OBJ[item.value]];
    return (
      <div className={styles.tabTitle}>
        <span>{t(item.value, { ns: 'enums' })}</span>
        {num != '0' && <span className={styles.statisc}>{num}</span>}
      </div>
    );
  };
  const tabOptions = [
    {
      label: labelNode({ value: 'ALL' }),
      children: <div>{messageContent}</div>,
    },
    ...MESSAGE_TYPE.map((value) => ({
      value,
      label: labelNode({ value }),
      children: <div>{messageContent}</div>,
    })),
  ];

  const drawerProps = {
    width: '770',
    closable: false,
    open,
    onClose,
  };
  const [currTab, setTab] = useState<string>();
  const tabProps = {
    tabPosition: 'left',
    destroyInactiveTabPane: true,
    activeIcon: <IconFont type="easygo-icon-a-bianzu82" />,
    options: tabOptions,
    onChange: (value: API.MessageListReqVO['type']) => {
      getUndealMessage.run();
      setSearchParam({ ...searchParam, type: value, page: 1 });
      setTab(value);
    },
    activeKey: currTab,
  };
  const handleLevelChange = (value: API.MessageListReqVO['level']) => {
    setSearchParam({ ...searchParam, level: value });
  };
  const handleStatusChange = (value: API.MessageListReqVO['status']) => {
    setSearchParam({ ...searchParam, status: value });
  };

  // 订阅
  useSubscript('Open_Message_Drawer', ({ open, params, categoryName }) => {
    setOpen(open);
    setTab(params.type);
    const cateName = {
      newOcean: i18n?.t('新海运'),
    }[categoryName];
    const category = categoryArrList.find((cate) => cate.label === cateName);
    paramsChangeHandler({
      ...params,
      categoryId: category?.value ? [category?.value] : [],
    });
  });
  const paramsChangeHandler = (data: API.MessageListReqVO) => setSearchParam({ ...searchParam, ...data });

  return (
    <Drawer {...drawerProps}>
      <div className={styles.messageBox}>
        <div className={styles.header}>
          <div>
            <span>{t('message_type', { ns: 'cashier' })}</span>
            <Select
              value={searchParam.status}
              onChange={(value) => handleStatusChange(value)}
              options={getEnumsLabelValue(MessageStatus, t, { ns: 'enums' })}
            ></Select>
          </div>
          <div className={styles.headerTitle}>
            <span>{t(searchParam.type ?? 'ALL', { ns: 'enums' })}</span>
            {(getUndealMessage?.data as any)?.[MESSAGE_TYPE_OBJ[searchParam.type ?? 'ALL']] != '0' && (
              <span className={styles.headerStatisc}>{(getUndealMessage?.data as any)?.[MESSAGE_TYPE_OBJ[searchParam.type ?? 'ALL']]}</span>
            )}
          </div>
        </div>
        <EasyTabs {...tabProps}></EasyTabs>
        <ProPagination {...paginationProps} />
      </div>
    </Drawer>
  );
});

export default MessageBox;
