import { Checkbox } from 'antd';
import type { CheckboxProps } from 'antd';
import { useComponentTrans } from '../config';
import styles from './index.module.less';
import type { API } from '@easygo/service/src/sale';

interface Props {
  agreements: API.CertificationResourceVO[];
  checkbox?: CheckboxProps;
}
const ReadedAgreement = ({ agreements, checkbox }: Props) => {
  const t = useComponentTrans();
  const handleView = (realUrl?: string) => () => {
    realUrl && window.open(realUrl, '_blank');
  };
  return (
    <p className={styles.agreement}>
      <Checkbox className="check" {...checkbox} />
      {[
        t('readed_agreement_all').split('PLACEFLAG')[0],
        agreements?.reduce((all, arg, i) => {
          if (i === agreements.length - 1) {
            all.push(
              <span className="xy" onClick={handleView(arg.previewUrl)} key={i}>
                《{arg.name}》
              </span>
            );
          } else {
            all.push(
              <>
                <span className="xy" onClick={handleView(arg.previewUrl)} key={i}>
                  《{arg.name}》
                </span>
                、
              </>
            );
          }
          return all;
        }, [] as any[]),
        t('readed_agreement_all').split('PLACEFLAG')[1],
      ]}
    </p>
  );
};

export default ReadedAgreement;
