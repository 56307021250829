import { i18n } from '@easygo/i18n';
import { useState } from 'react';
import type { FC } from 'react';
import { useDebounceFn } from 'ahooks';
import { Button, Space, Divider } from 'antd';
import { enums } from '@easygo/utils';
import { useTranslation } from 'next-i18next';
import { Auth, EasyDrawer, IconFont } from '@easygo/components';
import PriceInformation from '../priceInformation';
import styles from './index.module.less';
import ReadedAgreement from '@easygo/components/src/readed-agreement';
import { useCheckEnterpriseAuthorization } from '../../../../hooks/useCheckEnterpriseCert';
import { PLATFORM_TYPE } from '@easygo/utils/src/enums';
import type { feeInfo } from '../utils';
import { formatByCoinType, formatFeeAmount } from '../utils';
import BigNumber from 'bignumber.js';
import type { boxTypeItem } from '../boxType';
import { CurrencyAbbr } from '@easygo/utils/src/enums/ocean';
import type { apis } from '@easygo/service';
import { utils } from '@easygo/utils';
import { useSimleMutipleCurrencyAmountFormator } from '@easygo/components/src/hooks/useMultipleCurrencyAmount';

const { getAmountComputeStr } = utils;
const { ACTION_TYPE } = enums;
interface OrderAmountProps {
  skuBoxInfo: boxTypeItem[];
  surchargeDetailList?: apis.API.SurchargeDetailDTO[];
  submitLoading?: boolean;
  handleSubmit?: any;
  handleChangePrice?: any;
  type: any;
  rateServer?: any;
  checked: boolean;
  handleCheck: any;
}
// 23.06 舱位费币种必定是美元
export const computeBaseAmount = (skuBoxInfo: boxTypeItem[]) => ({
  price: skuBoxInfo?.reduce((res, item) => BigNumber(res).plus(BigNumber(item.price).times(item.planCount)).toNumber(), 0),
  currency: CurrencyAbbr.CURRENCY_TYPE_DOLLAR,
});

export const computeExtraFees = (skuBoxInfo: boxTypeItem[], surchargeDetailList: apis.API.SurchargeDetailDTO[]): feeInfo[] => {
  const ticketExtraFees = surchargeDetailList?.filter((item) => item.type === 'SURCHARGE_TYPE_EACH_TICKET');
  const boxExtraFees: feeInfo[] = [];
  skuBoxInfo?.forEach((item) => {
    surchargeDetailList?.forEach(({ boxTypeId, surchargeEntryName, currencyValue, currencyType }) => {
      if (String(boxTypeId) === String(item.boxId)) {
        boxExtraFees.push({
          boxId: boxTypeId,
          surchargeEntryName,
          price: BigNumber(currencyValue).times(item.planCount).toNumber(),
          currency: currencyType,
          planCount: item.planCount,
          unitPrice: currencyValue,
        });
      }
    });
  });
  return [
    ...ticketExtraFees?.map(({ currencyValue: price, currencyType: currency, surchargeEntryName }) => ({
      price,
      currency,
      surchargeEntryName,
    })),
    ...boxExtraFees,
  ];
};
const OrderAmount: FC<OrderAmountProps> = (props) => {
  const {
    surchargeDetailList = [],
    skuBoxInfo,
    submitLoading = false,
    handleSubmit,
    handleChangePrice,
    type,
    checked = false,
    handleCheck,
    rateServer,
  } = props ?? {};
  const baseAmount = computeBaseAmount(skuBoxInfo);
  const extraFees = computeExtraFees(skuBoxInfo, surchargeDetailList);
  const { checkAgreements } = useCheckEnterpriseAuthorization();
  const formator = useSimleMutipleCurrencyAmountFormator();
  //未选船期或者订舱舱量为0
  const disabled = !skuBoxInfo?.[0]?.planDepartureTime || skuBoxInfo.reduce((res, item) => BigNumber(item.planCount).plus(res).toNumber(), 0) === 0;

  // 面板费用明细
  const allFees = [
    {
      tit: '海运订舱',
      subFees: [
        {
          tit: '舱位费',
          valStr: getAmountComputeStr(
            skuBoxInfo?.map(({ price, planCount, currentType }) => ({ price, count: planCount, currency: currentType })),
            formator
          ),
        },
        // 附加费
        ...getAddtionalFee(skuBoxInfo || [], surchargeDetailList || [], formator),
      ],
    },
    {
      tit: '优惠',
      subFees: [],
    },
  ];

  const agreements = checkAgreements({
    platformType: PLATFORM_TYPE.BUIER,
    businessType: 'marineCabin',
  });

  const { t } = useTranslation('ocean');
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  /**
   * @method 协议勾选
   * @param e
   */
  const handleChecked = (e: any) => {
    const check = e.target.checked;
    handleCheck?.(check);
  };

  /**
   * @method 确认订舱
   */
  const { run: onSubmit } = useDebounceFn(
    () => {
      handleSubmit?.();
    },
    { wait: 300 }
  );

  /**
   * @method 查看资金明细
   */
  const handleViewDetails = () => {
    setDrawerOpen(!drawerOpen);
  };

  // 总额
  const totalAmountStr = formator([baseAmount, ...extraFees].map((item) => ({ currency: item.currency, amount: item.price })) as any);

  return (
    <>
      <div className={styles.orderAmountContainer}>
        <Space size={16} direction="vertical" className={styles.container}>
          <div className={styles.cardTitle}>
            {t('order_price')}
            <span className={styles.detailBtn} onClick={handleViewDetails}>
              明细
              <IconFont style={{ marginLeft: '10px' }} type="easygo-icon-MoreTip" />
            </span>
          </div>

          {allFees.map(({ tit, subFees }, i) => (
            <div className={styles.block} key={i}>
              <Space size={4} direction="vertical" className={styles.container}>
                <h3 className={styles.tit}>{tit}</h3>
                {subFees.map((sub, j) => (
                  <p className={styles.subtit} key={j}>
                    <span>{sub.tit}: </span>
                    <span>{sub.valStr}</span>
                  </p>
                ))}
                {!subFees.length && <p className={styles.subtit}>-</p>}
              </Space>
            </div>
          ))}

          <Divider style={{ margin: 0 }} />
          <div className={styles.block}>
            <Space size={4} direction="vertical" className={styles.container}>
              <h3 className={styles.tit}>总计</h3>
              <p className={`${styles.subtit} ${styles.total}`}>{!disabled ? totalAmountStr : '-'}</p>
            </Space>
          </div>

          <div className={styles.actionContainer}>
            <Auth
              auth={type === ACTION_TYPE.CONFIRM_ORDER}
              or={
                <>
                  <Button disabled={disabled} onClick={onSubmit} type="primary" loading={submitLoading}>
                    <Auth auth={type === ACTION_TYPE.PLACE_ORDER} or={t('confirm_edit')}>
                      {t('space_immediately')}
                    </Auth>
                  </Button>
                  <Auth auth={type === ACTION_TYPE.PLACE_ORDER}>
                    <ReadedAgreement agreements={agreements ? agreements.list : []} checkbox={{ onChange: handleChecked, checked }} />
                  </Auth>
                </>
              }
            >
              <Space direction="vertical" className={styles.actionBtn} size={12}>
                <Button onClick={onSubmit} type="primary" loading={submitLoading} className={styles.btn}>
                  {t('receive_order')}
                </Button>
                <Button onClick={handleChangePrice} className={styles.btn}>
                  {t('change_price')}
                </Button>
              </Space>
            </Auth>
          </div>
        </Space>
      </div>
      <EasyDrawer open={drawerOpen} title={t('order_price_details')} width={800} onClose={handleViewDetails}>
        <PriceInformation rateServer={rateServer} hiddenPrice={disabled} quantity={0} baseFee={skuBoxInfo} extraFees={extraFees} />
      </EasyDrawer>
    </>
  );
};

export default OrderAmount;

type FeeItem = NonNullable<Parameters<typeof getAmountComputeStr>[0]>[0];
/** 获取附加费计算的逻辑字符串 */
function getAddtionalFee(skuBoxInfo: boxTypeItem[], surchargeDetailList: apis.API.SurchargeDetailDTO[], formator: any) {
  // 是按每票收的费用
  const ticketExtraFees = surchargeDetailList.filter((item) => item.type === 'SURCHARGE_TYPE_EACH_TICKET');
  const ticket = ticketExtraFees.map((item) => {
    return {
      tit: item.surchargeEntryName,
      valStr: skuBoxInfo.length ? getAmountComputeStr([{ count: 1, price: item.currencyValue, currency: item.currencyType }], formator) : '-',
    };
  });
  // 是按每柜收的费用
  const eachBoxExtraFees = surchargeDetailList.filter((item) => item.type === 'SURCHARGE_TYPE_EACH_BOX');
  const splitEachBox = eachBoxExtraFees.reduce((all, curr) => {
    const targetFees = all[curr.surchargeEntryName] || [];
    targetFees.push(curr);
    all[curr.surchargeEntryName] = targetFees;
    return all;
  }, {}) as Record<string, apis.API.SurchargeDetailDTO[]>;
  const eachBox = Object.entries(splitEachBox).map(([surchargeEntryName, feeTempList]) => {
    const arr: FeeItem[] = skuBoxInfo.map((skubox) => {
      const feeTmp = feeTempList.find((item) => item.boxTypeId === skubox.boxId);
      return {
        price: feeTmp?.currencyValue || 0,
        currency: feeTmp?.currencyType,
        count: skubox.planCount,
      };
    });
    return {
      tit: surchargeEntryName,
      valStr: getAmountComputeStr(arr, formator),
    };
  });

  return [...ticket, ...eachBox];
}
