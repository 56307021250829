import mitt from 'mitt';
import type { Emitter } from 'mitt';
import { useEffect } from 'react';
import type { API } from '@easygo/service/src/apis/api/typings';

type Events = {
  /** 打开金额明细事件 */
  OPEN_ORDERDETAIL_AMOUNTDETAIL: boolean;
  /** 打开消息抽屉事件 */
  Open_Message_Drawer: {
    open: boolean;
    params: API.MessageListReqVO;
    // 暂时就添加新海运
    categoryName: 'newOcean';
  };
};

export const emitter = mitt<Events>();

export default emitter;

/** 发布订阅 */
export const useSubscript: Emitter<Events>['on'] = (type: any, handler: any) => {
  useEffect(() => {
    emitter.on(type, handler);
    return () => emitter.off(type, handler);
  }, [type, handler]);
  return emitter;
};
