import { useRequest } from 'ahooks';
import { useEffect, useState, useRef, useMemo } from 'react';
import type { FC } from 'react';
import { Form, Input, Col, message, Row, InputNumber } from 'antd';
import { ProForm, ProFormField, IconFont, Select } from '@easygo/components';
import { EasygoUpload, EasygoModal } from '@easygo-athena/components';
import style from './index.module.less';
import { CurrencyUnicodeSymbol, CurrencyType } from '@easygo/utils/src/enums';
import type { SelectAccountInfoRef } from '../select-account-info';
import SelectAccountInfo from '../select-account-info';
import { resolveCurrency } from '@easygo/utils/src/utils';
import { round } from 'lodash';
import BigNumber from 'bignumber.js';

export const PAYOUTS_INFO_LIST = [
  '收款账户仅限已经在平台进行绑定的同名银行账户；',
  '人民币提现仅能提现至人民币账户；',
  '美金提现，根据提现币种仅能选择对应币种的银行账户；',
  '提现到账时间预计24小时，如超时未到账可联系平台客服 400-666-5517',
];

export const calcRate = (a: number, b: number) => (a > 0 && b > 0 ? round(a / b, 4).toFixed(4) : '');

const CURRENCY_TYPE = {
  [CurrencyType.RMB]: {
    key: CurrencyType.RMB,
    symbol: CurrencyUnicodeSymbol.YEN,
    text: '人民币',
  },
  [CurrencyType.DOLLAR]: {
    key: CurrencyType.DOLLAR,
    symbol: CurrencyUnicodeSymbol.DOLLAR,
    text: '美元',
  },
};

type Req = (...args: any) => Promise<any>;

export interface ReqPayoutsModalProps {
  rowData: any;
  onCancel: () => void;
  onOk: () => void;
  /** 下拉接口 */
  listServer: Req;
  /** 编辑接口 */
  editAccountServer?: Req;
  /** 创建接口 */
  createAccountServer?: Req;
  /** 移除接口 */
  deleteAccountServer?: Req;
  /** 获取详情接口 */
  getAccountInfoServer?: Req;
  /** 获取城市接口 */
  getCityServer?: Req;
  // 提交接口
  submitServer: Req;
  // 获取资金可用余额
  getAvlAmountServer: Req;
  // 提现详情（可用作修改数据回显）
  getDetail?: Req;
  // 是否显示上传组件
  showUpload?: boolean;
  open: boolean;
  currencyType?: CurrencyType.RMB | CurrencyType.DOLLAR;
  // 银行卡账户是否支持修改
  canEdit?: boolean;
  // 银行卡账户是否支持新增
  canCreate?: boolean;
  // 是否显示实际到账金额
  showActualAmount?: boolean;
  // 获取实际到账金额及汇率
  getActulAmountServer: Req;
}

const Index: FC<ReqPayoutsModalProps> = (props) => {
  const {
    rowData,
    onCancel,
    onOk,
    listServer,
    submitServer,
    createAccountServer,
    getAccountInfoServer,
    editAccountServer,
    getCityServer,
    getAvlAmountServer,
    open,
    showUpload = false,
    canEdit = true,
    canCreate = true,
    showActualAmount = false,
    getActulAmountServer,
  } = props;
  // 弹窗表单
  const [form] = Form.useForm();
  const actAmountValue = Form.useWatch('actAmount', form);
  const amountValue = Form.useWatch('amount', form);
  const currencyTypeValue = Form.useWatch('currencyType', form);

  const cardRef = useRef<SelectAccountInfoRef>(null);
  const [avlAmount, setAvlAmount] = useState<any>({});
  // 试算流水
  const [trialData, setTrialData] = useState<any>({});
  const [canAddAccount, setCanAddAccount] = useState<any>(true);
  const { loading: submitLoading, runAsync: submit } = useRequest(submitServer, { manual: true, debounceMaxWait: 300 });

  const showRate = useMemo<boolean>(() => {
    return currencyTypeValue === CurrencyType.RMB && avlAmount.accountFundAvlCoin === CurrencyType.DOLLAR;
  }, [currencyTypeValue, avlAmount.accountFundAvlCoin]);

  /**
   * @method 确认操作
   */
  const handleConfirm = async () => {
    const res = await form.validateFields();
    const cardInfo = cardRef.current?.checkInfo || {};
    const params = {
      ...res,
      trailDetails: trialData?.details,
      docUrls: res.docUrls?.map((i: any) => i.publicUrlOrPrivateId),
      recordAccountId: rowData.offWalletAccountId,
      memberAccountBankCardBindSubBankName: cardInfo.subBankName,
      memberAccountBankCardBindEnterpriseName: cardInfo.enterpriseName,
      memberAccountBankCardBindBankNo: cardInfo.cardNo,
      memberAccountBankCardBindBankName: cardInfo.bankName,
      memberAccountBankCardBindCoin: cardInfo.coin,
    };
    showActualAmount && showRate && (params.recordRate = trialData?.averageRate);
    await submit(params);
    message.success('提交成功');
    onOk?.();
  };

  // 全部提现
  const setAllAmount = () => {
    form.setFieldsValue({
      amount: +avlAmount.accountFundAvlAmount,
    });
    handleTrial();
  };

  // 实时获取可用金额
  const fetchAvlAmount = async () => {
    const res = getAvlAmountServer && (await getAvlAmountServer({ accountId: rowData?.offWalletAccountId }));
    setAvlAmount(res || {});
  };

  // 获取实际到账金额
  const handleTrial = () => {
    setTimeout(async () => {
      const value = form.getFieldValue('amount');
      if (!!value) {
        if (avlAmount.accountFundAvlCoin !== CurrencyType.RMB) {
          const res = await getActulAmountServer({
            memberAccountId: rowData.offWalletAccountId,
            applyAmount: value,
          });
          setTrialData(res || {});
          form.setFieldValue('actAmount', res?.amount);
        } else {
          form.setFieldValue('actAmount', value);
        }
      }
    }, 50);
  };

  useEffect(() => {
    open ? fetchAvlAmount() : setTrialData({});
  }, [open]);

  useEffect(() => {
    // 人民币->人民币时 实际到账金额 = 提现金额
    if (avlAmount.accountFundAvlCoin === currencyTypeValue) {
      form.setFieldValue('actAmount', amountValue);
    }
  }, [amountValue, avlAmount, currencyTypeValue]);

  return (
    <EasygoModal
      onCancel={onCancel}
      destroyOnClose
      title="申请提现"
      width={640}
      open={open}
      confirmLoading={submitLoading}
      okText="提交申请"
      onOk={handleConfirm}
    >
      <>
        <div className={style.info}>
          <IconFont type="easygo-icon-Toast-Warning" style={{ color: '#FF8413', fontSize: 22 }} />
          <div className={style.left}>
            {PAYOUTS_INFO_LIST.map((item, index) => {
              return (
                <div className={style.info_item} key={item}>
                  {`${index + 1}、${item}`}
                </div>
              );
            })}
          </div>
        </div>
        <div
          style={{
            marginBottom: 24,
          }}
        >
          <div className={style.lable_title}>可提现金额</div>
          <div className={style.lable_value}>
            <span style={{ fontSize: 24, color: '#1A1E2E', fontWeight: 700 }}>
              {resolveCurrency({ price: avlAmount.accountFundAvlAmount, currency: avlAmount.accountFundAvlCoin })}
            </span>
          </div>
        </div>
        <ProForm
          form={form}
          layout="vertical"
          initialValues={{
            currencyType: CurrencyType.RMB,
          }}
          preserve={false}
        >
          <Row gutter={32}>
            <Col span={24}>
              <ProFormField
                name="amount"
                // extra={
                //   <>
                //     <span style={{ marginRight: 5, color: '#FF8413' }}>提现手续费：￥7.23</span>
                //     <span>最终到账金额以实际为准</span>
                //   </>
                // }
                label="提现金额"
                rules={[
                  { required: true, message: '请输入' },
                  {
                    validator(_, value) {
                      if (value < 0.01) {
                        return Promise.reject(new Error('最小值为0.01'));
                      }

                      if (avlAmount.accountFundAvlAmount && BigNumber(value).comparedTo(BigNumber(avlAmount.accountFundAvlAmount)) > 0) {
                        return Promise.reject(new Error(`最大值为${avlAmount.accountFundAvlAmount}`));
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  stringMode
                  placeholder="请输入"
                  addonBefore={CURRENCY_TYPE[avlAmount.accountFundAvlAmount as keyof typeof CURRENCY_TYPE]?.symbol || null}
                  addonAfter={
                    <div style={{ cursor: 'pointer' }} onClick={setAllAmount}>
                      全部
                    </div>
                  }
                  min={0.01}
                  // max={avlAmount.accountFundAvlAmount ?? undefined}
                  precision={2}
                  onBlur={handleTrial}
                />
              </ProFormField>
            </Col>
            <Col span={24}>
              <ProFormField
                label="提现到账币种"
                name="currencyType"
                extra={
                  showRate ? (
                    <span style={{ color: '#FF8413', fontWeight: 400 }}>
                      取消订单（或部分取消订单），原则上按照收款币种退款，如果外币需要折算为人民币退款的，则按照最后一次取消的订单显示汇率/付款日汇率/退款日汇率折算人民币后孰低的标准退款
                    </span>
                  ) : null
                }
              >
                <Select
                  disabled
                  options={Object.values(CURRENCY_TYPE).map(({ key, text }) => ({
                    value: key,
                    label: text,
                  }))}
                />
              </ProFormField>
            </Col>
            {showActualAmount && (
              <Col span={24}>
                <ProFormField
                  name="actAmount"
                  extra={showRate ? <span style={{ color: 'gray' }}>汇率：{trialData?.averageRate}</span> : null}
                  label="到账金额"
                  rules={[
                    { required: true, message: '请输入' },
                    {
                      validator(_, value) {
                        if (value >= 0.01) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('最小值为0.01'));
                      },
                    },
                  ]}
                >
                  <InputNumber
                    addonBefore={CURRENCY_TYPE[currencyTypeValue as keyof typeof CURRENCY_TYPE]?.symbol || null}
                    style={{ width: '100%' }}
                    precision={2}
                    stringMode
                    disabled
                  />
                </ProFormField>
              </Col>
            )}
            <Col span={24}>
              <ProFormField label="提现到账账户" name="memberAccountBankCardBindId" rules={[{ required: true, message: '请选择' }]}>
                <SelectAccountInfo
                  canCreate={canCreate && canAddAccount}
                  ref={cardRef}
                  placeholder="请选择收款账户"
                  // 需要过滤出对应币种的银行卡
                  listServer={() =>
                    listServer(rowData).then((res) => {
                      //所有币种银行卡加起来总和不超过4张
                      if (res?.bankCards?.length >= 4) {
                        setCanAddAccount(false);
                      } else {
                        setCanAddAccount(true);
                      }
                      // 本期固定人民币
                      const result = res?.bankCards?.filter((i: any) => i.coin === CurrencyType.RMB);
                      form.setFieldValue('memberAccountBankCardBindId', result?.[0]?.bankCardId);
                      if (!result?.length && canCreate && canAddAccount) {
                        message.warning('未找到当前币种银行卡！请绑定对应币种银行卡后提现！');
                      }
                      return {
                        bankCards: result,
                      };
                    })
                  }
                  getAccountInfoServer={getAccountInfoServer}
                  createAccountServer={createAccountServer}
                  editAccountServer={editAccountServer}
                  getCityServer={getCityServer}
                  canEdit={canEdit}
                />
              </ProFormField>
            </Col>
            <Col span={24}>
              <ProFormField label="备注" name="remark">
                <Input.TextArea placeholder="请输入" rows={3} maxLength={200}></Input.TextArea>
                {/* <CardInfo></CardInfo> */}
              </ProFormField>
            </Col>
            {showUpload && (
              <Col span={24}>
                <ProFormField label="提现凭证" name="docUrls" rules={[{ required: true, message: '请上传' }]}>
                  <EasygoUpload listType="picture-card" maxCount={5} />
                </ProFormField>
              </Col>
            )}
          </Row>
        </ProForm>
      </>
    </EasygoModal>
  );
};

export default Index;
