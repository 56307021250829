import React, { useCallback, useEffect } from 'react';
import { useRequest } from 'ahooks';
import { Form, Select, Input, Row, Col } from 'antd';
import { EasyCascader, useGlobalState } from '@easygo/components';
import { EasygoModal } from '@easygo-athena/components';
import { BankCardTypeOptions, CoinTypeTypeLabel, CurrencyTypeMap } from '@easygo/utils/src/enums';
import styles from './index.module.less';
import { getEnumsLabelValue } from '@easygo/utils/src/utils';

type Req = (...args: any) => Promise<any>;
interface AccountModalProps {
  title: string;
  open: boolean;
  bankCardId: string;
  onCancel: () => void;
  onOk: (params: string | number, formInfo: any) => void;
  confirmAccountServer: Req;
  getAccountInfoServer: Req;
  editAccountServer: Req;
  deleteAccountServer?: Req;
  getCityServer: Req;
  actionType: 'CREATE' | 'EDIT';
}
const AccountModal: React.FC<AccountModalProps> = (props) => {
  const {
    open = false,
    onCancel,
    onOk,
    confirmAccountServer,
    editAccountServer,
    deleteAccountServer,
    getAccountInfoServer,
    getCityServer,
    title = '',
    bankCardId,
    actionType = 'CREATE',
  } = props;
  const {
    state: { bussiness },
  } = useGlobalState();
  const [form] = Form.useForm();
  const { loading: submitLoading, runAsync: submit } = useRequest(confirmAccountServer, {
    manual: true,
    debounceMaxWait: 300,
    ready: !!confirmAccountServer,
  });
  const { loading: editLoading, runAsync: edit } = useRequest(editAccountServer, { manual: true, debounceMaxWait: 300, ready: !!editAccountServer });

  /**
   * @method 数据请求
   */
  const getAccount = useCallback(async () => {
    const data = await getAccountInfoServer?.();
    const targetInfo = data?.bankCards?.find((item: any) => item.bankCardId === bankCardId);
    form.setFieldsValue({ ...targetInfo, cityInfo: targetInfo && [targetInfo?.provinceId, targetInfo?.cityId] });
  }, [getAccountInfoServer, bankCardId, form]);

  useEffect(() => {
    if (open && actionType === 'EDIT') getAccount();
    form.setFieldsValue({ enterpriseName: bussiness?.name });
  }, [open, actionType, getAccount, form, bussiness]);

  /**
   * @method 确认操作
   */
  const handleConfirm = async () => {
    const res = await form.validateFields();
    const params = { bankCardId, ...res, provinceId: res?.cityInfo[0], cityId: res?.cityInfo[1] };
    delete params?.cityInfo;
    const server = actionType === 'CREATE' ? submit : edit;
    const bankCardInfo = await server(params);
    onOk?.(bankCardInfo?.id, params);
  };

  return (
    <EasygoModal
      open={open}
      title={title}
      destroyOnClose
      onCancel={onCancel}
      onOk={handleConfirm}
      okButtonProps={{ loading: submitLoading || editLoading }}
    >
      <div className={styles.tips}>
        <div>1. 一个钱包账户下仅支持绑定一个人民币账户和一个美元账户。</div>
        <div>2. 您绑定的账户信息将会在买家下单并选择线下对公转账时进行展示，因此请务必保证账户信息填写的正确性！</div>
      </div>
      <Form layout="vertical" form={form} preserve={false}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="账户名称" name="enterpriseName" rules={[{ required: true }, { whitespace: true, message: '请输入账户名称' }]}>
              <Input placeholder="请输入账户名称" disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="账户币种" name="coin" rules={[{ required: true }]}>
              <Select
                placeholder="请选择账户币种"
                options={getEnumsLabelValue(CoinTypeTypeLabel)?.filter((item) =>
                  [CurrencyTypeMap.RMB, CurrencyTypeMap.DOLLAR].includes(item?.value as keyof typeof CoinTypeTypeLabel)
                )}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="账户类型" name="bankCardType" rules={[{ required: true }]}>
              <Select placeholder="请选择账户类型" options={BankCardTypeOptions} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="开户行"
              name="bankName"
              rules={[{ required: true }, { whitespace: true, message: '请输入开户行名称' }, { max: 50, message: '仅支持输入50位字符' }]}
            >
              <Input placeholder="请输入开户行名称" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="城市" name="cityInfo" rules={[{ required: true }]}>
              <EasyCascader selectProps={{ api: getCityServer, optionParams: { size: -1, level: 3 } }} placeholder="请选择城市" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="开户支行"
              name="subBankName"
              rules={[{ required: true }, { whitespace: true, message: '请输入账户名称' }, { max: 50, message: '仅支持输入50位字符' }]}
            >
              <Input placeholder="请输入开户支行" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="银行账号"
              name="cardNo"
              rules={[{ required: true }, { pattern: /^[0-9][0-9]*$/g, message: '请输入正确的银行卡号' }, { max: 20, message: '仅支持输入20位字符' }]}
            >
              <Input placeholder="请输入银行账号" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </EasygoModal>
  );
};

export default AccountModal;
