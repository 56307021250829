import { i18n } from '@easygo/i18n';
import { createEnums } from '@easygo/utils/src/utils';

export const MessageLevel = createEnums(['ALL', 'NOTIFY_LEVEL_REMIND', 'NOTIFY_LEVEL_ABNORMAL', 'NOTIFY_LEVEL_TODO']);

/** 消息状态
MESSAGE_STATUS_UNKNOWN_未知状态
MESSAGE_STATUS_UNPROCESSED _未处理
MESSAGE_STATUS_PROCESSED _已处理
 */

export const MessageStatus = createEnums(['MESSAGE_STATUS_UNPROCESSED', 'MESSAGE_STATUS_PROCESSED']);
export const MESSAGE_TYPE = [
  'NOTIFY_TYPE_TRADING_REMIND',
  'NOTIFY_TYPE_RFQ',
  'NOTIFY_TYPE_CONVENTIONS',
  'NOTIFY_TYPE_PROMOTION',
  'NOTIFY_TYPE_SERVICE_NOTICE',
  'NOTIFY_TYPE_FUND_NOTICE',
  // 'NOTIFY_TYPE_YARD_NOTICE',
  'NOTIFY_TYPE_OFFICIAL_NOTICE',
  'NOTIFY_TYPE_ANNOUNCEMENT',
];

export const MESSAGE_TYPE_OBJ = {
  NOTIFY_TYPE_TRADING_REMIND: 'tradingRemind',
  NOTIFY_TYPE_RFQ: 'rfq',
  NOTIFY_TYPE_CONVENTIONS: 'conventions',
  NOTIFY_TYPE_PROMOTION: 'promotion',
  NOTIFY_TYPE_SERVICE_NOTICE: 'serviceNotice',
  NOTIFY_TYPE_FUND_NOTICE: 'fundNotice',
  NOTIFY_TYPE_OFFICIAL_NOTICE: 'officialNotice',
  NOTIFY_TYPE_ANNOUNCEMENT: 'announcement',
  ALL: 'totalNum',
  NOTIFY_TYPE_UNKNOWN: '',
  UNRECOGNIZED: '',
  NOTIFY_TYPE_SYSTEM: '',
  // NOTIFY_TYPE_YARD_NOTICE: 'yardNotice',
};

export const bussinessIcon = {
  // 租箱
  '9108763151749120': 'easygo-icon-zuxiang-heise',
  // 报关
  '9108763940278272': 'easygo-icon-baoguan-hei',
  // 拖车
  '9108764598784000': 'easygo-icon-tuoche-hei',
  // 海运
  '9108765257289728': 'easygo-icon-haiyun-hei',
  // 班列
  '9108765907406848': 'easygo-icon-tieyuncang-hei',
  // 新海运
  '64205420956274688': 'easygo-icon-haiyun-hei',
  // 堆场
  '0': '',
};

export const typeIcon = {
  // 资金变动
  NOTIFY_TYPE_FUND_NOTICE: 'easygo-icon-zijin-moren',
};

export const typeLabel = {
  // 资金变动
  NOTIFY_TYPE_FUND_NOTICE: i18n?.t('资金变动'),
};
