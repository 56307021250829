import styles from './index.module.less';

import React, { useEffect, useMemo, useState } from 'react';
import type { StatementItem, PaymentItem } from '../../../../models/bill';
import type { ColumnsType } from 'antd/lib/table';
import Table from 'antd/lib/table';
import { multipleCurrencyAmountToDisplay, useMultipleCurrencyAmountToDisplay } from '@easygo/components/src/hooks/useMultipleCurrencyAmountToDisplay';
import { CurrencyLetterMap, CurrencySymbolMap } from '@easygo/utils/src/enums';
import BigNumber from 'bignumber.js';
import type { CurrencyAmount } from '../../../../utils/typings';
import { EasyModal } from '@easygo/components';
import { Form, Input, InputNumber } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { i18n } from '@easygo/i18n';

export interface PaymentSolutionProps {
  statementItem: StatementItem;
  open: boolean;
  onClose: () => void;
  onOk: (payload: any) => void;
}

/** 未完成，暂时废弃 */
const EditPaymentSolutionModal: React.FC<PaymentSolutionProps> = ({ statementItem, open, onClose, onOk }) => {
  const [form] = useForm();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      paymentItems: statementItem.paymentItems,
    });
  }, [form, statementItem]);

  const submit = () => {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    form
      .validateFields()
      .then(({ rmbAmount, dollarAmount, rate, remark }) => {
        return onOk({});
      })
      .catch(() => {})
      .finally(() => setSubmitting(false));
  };

  return (
    <EasyModal
      width={800}
      title={'付款方案'}
      okText="确认生成账单"
      wrapClassName={styles['edit-payment-solution-modal']}
      bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
      maskClosable={false}
      visible={open}
      handleCancel={onClose}
      onOk={submit}
      okButtonProps={{ loading: submitting }}
    >
      <div className={styles['payment-info']}>
        <div className={styles.item}>
          <div className={styles.title}>账单金额</div>
          <div className={styles.value}>{multipleCurrencyAmountToDisplay([statementItem.originFee])}</div>
        </div>
      </div>

      <Form form={form} onFinish={onOk} layout="vertical">
        <div className={styles['editable-table']}>
          <div className={styles.header}>
            <div className={styles.cell}>账单金额拆分</div>
            <div className={styles.cell}>付款币种</div>
            <div className={styles.cell}>汇率</div>
            <div className={styles.cell}>应付金额</div>
            <div className={styles.cell}>操作</div>
          </div>
          <Form.List name="paymentItems">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name, key }, index) => (
                  <div key={key} className={styles.row}>
                    <div className={styles.cell}>
                      <Form.Item noStyle name={[name, 'originCurrencyAmount']}>
                        <InputNumber
                          addonBefore={CurrencySymbolMap[statementItem.originFee.currency]}
                          style={{ width: '100%' }}
                          placeholder={i18n?.t('请输入')}
                        />
                      </Form.Item>
                    </div>
                    <div className={styles.cell}>
                      <Form.Item noStyle name={[name, 'paymentCurrency']}>
                        <Input maxLength={30} style={{ width: '100%' }} placeholder={i18n?.t('请输入')} />
                      </Form.Item>
                    </div>
                    <div className={styles.cell}>
                      <Form.Item noStyle name={[name, 'rate']}>
                        <InputNumber
                          addonBefore={`100${CurrencyLetterMap[statementItem.originFee.currency as keyof typeof CurrencyLetterMap]} ≈ `}
                          addonAfter={CurrencyLetterMap[statementItem.originFee.currency as keyof typeof CurrencyLetterMap]}
                          style={{ width: '100%' }}
                          placeholder={'请输入金额'}
                        />
                      </Form.Item>
                    </div>
                    <div className={styles.cell}>
                      {multipleCurrencyAmountToDisplay([
                        {
                          currency: statementItem.paymentItems[index].paymentCurrency,
                          amount: BigNumber(statementItem.paymentItems[index].originCurrencyAmount)
                            .times(statementItem.paymentItems[index].rate)
                            .div(100)
                            .toNumber(),
                        },
                      ])}
                    </div>
                    <div className={styles.cell}>
                      <span className={styles['btn-del']} onClick={() => remove(index)}>
                        删除
                      </span>
                    </div>
                  </div>
                ))}
              </>
            )}
          </Form.List>
        </div>
      </Form>
    </EasyModal>
  );
};

export default EditPaymentSolutionModal;
