import { i18n } from '@easygo/i18n';
import styles from './index.module.less';
import React from 'react';
import { GoodsTypeCheck } from '../goodsType';
import BaseCard from '../baseCard';

type Data = {
  goodsType: string[];
  otherGoodsRemark?: string;
};
type Props = {
  values?: any;
  className?: string;
  requestGoodsTypeEnum: (params?: any) => Promise<any>;
};
export type Instance = { getData(): Promise<Data> };

export default React.forwardRef<Instance, Props>(({ className, ...props }, ref) => {
  return (
    <BaseCard title="承运货物信息" tips="请选择准确的货物信息，避免被查验影响运输时效！" className={`${styles.mb1} ${styles.goodsRisk} ${className}`}>
      <GoodsTypeCheck ref={ref} {...props} />
    </BaseCard>
  );
});
