import type { apis } from '@easygo/service';
import { useRequest } from 'ahooks';

type BoxTypeDTO = {
  /** 当前箱型数据 */
  targetBoxInfo: { id: string; name: string; [key: string]: any };
  /** 全部箱型数据 */
  records: apis.API.IPageBoxTypeDTO;
};

interface BoxTypeProps {
  boxTypeId?: string | number;
  api: Promise<unknown, { query: { size: number } }[]>;
}

export function useBoxType(params: BoxTypeProps) {
  const { boxTypeId, api } = params ?? {};
  const { data } = useRequest(api, {
    defaultParams: [{ query: { size: -1 } }],
    ready: !!api,
  });
  const { records = [] } = (data ?? {}) as apis.API.IPageBoxTypeDTO;
  const targetBoxInfo = (records ?? []).find((item) => item.id === boxTypeId);
  return [targetBoxInfo, records] as [BoxTypeDTO['targetBoxInfo'], BoxTypeDTO['records']];
}
