/** 结算类型 */
export enum Settlement_Type {
  ORDER_SETTLEMENT_TYPE_PAY = 'ORDER_SETTLEMENT_TYPE_PAY',
  ORDER_SETTLEMENT_TYPE_PERIOD = 'ORDER_SETTLEMENT_TYPE_PERIOD',
}
export const Settlement_Type_Label = {
  [Settlement_Type.ORDER_SETTLEMENT_TYPE_PAY]: '付款买单',
  [Settlement_Type.ORDER_SETTLEMENT_TYPE_PERIOD]: '账期付',
};

/** 账单状态 */
export enum Settlement_Status {
  STATEMENT_STATUS_WAITING_CONFIRM = 'CONFIRM_STATUS_WAITING',
  STATEMENT_STATUS_FINISHED_CONFIRM = 'CONFIRM_STATUS_CONFIRM',
}
export const Settlement_Status_Label = {
  [Settlement_Status.STATEMENT_STATUS_WAITING_CONFIRM]: '待确认',
  [Settlement_Status.STATEMENT_STATUS_FINISHED_CONFIRM]: '已确认',
};

export const Settlement_Status_Config = {
  [Settlement_Status.STATEMENT_STATUS_WAITING_CONFIRM]: {
    label: '待确认',
    color: '#FF8413',
  },
  [Settlement_Status.STATEMENT_STATUS_FINISHED_CONFIRM]: {
    label: '已确认',
    color: '#28A051',
  },
};

/** 付款状态 */
export enum Settlement_Pay_Status {
  STATEMENT_PAY_STATUS_WAITING = 'STATEMENT_PAY_STATUS_WAITING',
  STATEMENT_PAY_STATUS_SUCCESS = 'STATEMENT_PAY_STATUS_SUCCESS',
  STATEMENT_PAY_STATUS_PARTIAL = 'STATEMENT_PAY_STATUS_PARTIAL',
}
export const Settlement_Pay_Status_Label = {
  [Settlement_Pay_Status.STATEMENT_PAY_STATUS_WAITING]: '待支付',
  [Settlement_Pay_Status.STATEMENT_PAY_STATUS_PARTIAL]: '部分支付',
  [Settlement_Pay_Status.STATEMENT_PAY_STATUS_SUCCESS]: '支付完成',
};
export const Settlement_Pay_Status_Config = {
  [Settlement_Pay_Status.STATEMENT_PAY_STATUS_WAITING]: {
    label: '待支付',
    color: '#FF8413',
  },
  [Settlement_Pay_Status.STATEMENT_PAY_STATUS_SUCCESS]: {
    label: '支付完成',
    color: '#28A051',
  },
  [Settlement_Pay_Status.STATEMENT_PAY_STATUS_PARTIAL]: {
    label: '部分支付',
    color: '#00A69F',
  },
};

/** 开票状态 */
export enum Invoice_Status {
  INVOICE_STATUS_WAITING = 'INVOICE_STATUS_WAITING',
  INVOICE_STATUS_COMPLETE = 'INVOICE_STATUS_COMPLETE',
}

export const Invoice_Status_Label = {
  [Invoice_Status.INVOICE_STATUS_WAITING]: '未开票',
  [Invoice_Status.INVOICE_STATUS_COMPLETE]: '已开票',
};

export const Invoice_Status_Config = {
  [Invoice_Status.INVOICE_STATUS_WAITING]: {
    label: '未开票',
    color: '#FF8413',
  },
  [Invoice_Status.INVOICE_STATUS_COMPLETE]: {
    label: '已开票',
    color: '#28A051',
  },
};
