import styles from './index.module.less';
import classNames from 'classnames/bind';
import { IconFont } from '@easygo/components';
const cx = classNames.bind(styles);

export type ActionType = 'add' | 'edit';
export type Item = { companyName: string; idNo: string; value: string; checked?: boolean; [key: string]: any };
export type ListProps = {
  list?: Item[];
  onAction?: (type: ActionType, item?: Item) => void;
  onChange?(item: Item): void;
};

const List: React.FC<ListProps> = ({ list, onAction, onChange }) => {
  const handler = (type: ActionType, item?: Item) => () => onAction?.(type, item);

  return (
    <>
      <div className={styles.lists}>
        {list?.map((item, i) => (
          <div className={cx('item', { active: item.checked })} key={i}>
            <div onClick={() => onChange?.(item)} className={styles.left}>
              <h1>{item.companyName}</h1>
              <p>纳税人识别号：{item.idNo}</p>
            </div>
            <IconFont onClick={handler('edit', item)} className={styles.icon} type="easygo-icon-banliejiedian-chuangjian" />
          </div>
        ))}
      </div>

      <div className={styles.add} onClick={handler('add')}>
        <IconFont type="easygo-icon-Add" className={styles.icon} />
        新增发票抬头
      </div>
    </>
  );
};
export default List;
