import { i18n } from '@easygo/i18n';
import { forwardRef, useImperativeHandle } from 'react';
import type { FC } from 'react';
import { Form, Row, Col, Space } from 'antd';
import styles from './index.module.less';
import { enums } from '@easygo/utils';
import { EnableStatus, InventoryStatus } from '@easygo/utils/src/enums/ocean';
import { useDict, useGlobalState, ProInputNumber } from '@easygo/components';
import { useCurrencyCodeAndSymbol } from '@easygo/components/src/hooks/useMultipleCurrencyAmount';
export interface boxTypeItem {
  boxId: string;
  price: string;
  planCount: string;
  currentType?: string;
  planDepartureTime?: string;
  skuId?: string;
  boxName?: string;
}
interface BoxTypeProps {
  onFieldsChange: (allValue: any) => void;
  ref?: any;
  boxTypeArr: boxTypeItem[];
  richLabel?: boolean;
  skus?: any[];
}

const AmountCard = ({ price = 0, currency }: { price: number; currency: string }) => {
  const { currencySymbol } = useCurrencyCodeAndSymbol();
  const priceStr = `${currencySymbol[currency]}${(+price)?.toFixed(2)}`;

  return (
    <Space size={4} direction="vertical" className={styles.amountCard}>
      <div className={styles.itemContainer}>
        <span className={styles.label}>{i18n?.t('单价')}</span>
        {price ? <span>{priceStr}</span> : '-'}
      </div>
    </Space>
  );
};
/**
 * @method 处理箱型展示数据
 */
const filterBoxType = (boxTypeArr: boxTypeItem[], boxTypeDict: any) => {
  const boxList: any = [];
  boxTypeArr?.forEach((item: any) => {
    const target = boxTypeDict?.find((boxItem: any) => boxItem.id === item.boxId);
    if (target) boxList.push({ ...target, ...item });
  });
  return boxList;
};

/**
 * @method 表单项渲染
 */
const renderFormItems = (boxTypeArr: boxTypeItem[], boxTypeDict: any, form: any, setting?: Record<string, any>) => {
  return (filterBoxType(boxTypeArr, boxTypeDict) ?? []).map((item: any) => {
    const { price = '', value: boxId, label: boxName, skuId, currentType, planDepartureTime, planCount = 1 } = item ?? {};
    const fieldsName = [boxId, boxName, skuId, price, currentType, planDepartureTime];
    form.setFieldValue(fieldsName, planCount);
    const targetSku = setting?.skus?.find((sku: any) => sku.id === skuId);
    const inventoryStatus = enums.InventoryStatusTxt[targetSku?.inventoryStatus as keyof typeof enums.InventoryStatusTxt];
    const disabled = targetSku?.inventoryStatus === InventoryStatus.OUT;

    return (
      <Col span={8} key={boxId} className={styles.col}>
        <Form.Item
          name={[boxId, boxName, skuId, price, currentType, planDepartureTime]}
          label={
            setting?.richLabel ? (
              <div className={styles.prolabel}>
                <p className={styles.boxType}>{boxName}</p>
                <p className={`${styles.tag} ${styles[targetSku?.inventoryStatus]}`}>{inventoryStatus}</p>
              </div>
            ) : (
              boxName
            )
          }
          colon={false}
          className={styles.inputNumber}
          extra={<AmountCard price={price} currency={currentType} />}
          required={false}
          initialValue={planCount}
        >
          <ProInputNumber min={0} max={999} precision={0} disabled={disabled} />
        </Form.Item>
      </Col>
    );
  });
};

const BoxType: FC<BoxTypeProps> = forwardRef((props, ref) => {
  const { onFieldsChange, boxTypeArr = [] } = props ?? {};
  const [form] = Form.useForm();
  const {
    state: { locale },
  } = useGlobalState();
  // 箱型
  const [boxTypeDict] = useDict('boxType', locale);

  useImperativeHandle(ref, () => ({
    boxTypeForm: form,
  }));

  /**
   * @method 表单字段变更
   * @param changedValues 已变更字段
   * @param allValue 所有字段
   */
  const onFormValuesChange = (_: any, allValue: any) => {
    onFieldsChange?.(allValue);
  };

  return (
    <Form form={form} onFieldsChange={onFormValuesChange} {...(props.richLabel ? { layout: 'vertical' } : {})}>
      <Row gutter={24}>
        {renderFormItems(
          boxTypeArr.sort((a, b) => a.boxId - b.boxId),
          boxTypeDict,
          form,
          { richLabel: props.richLabel, skus: props.skus }
        )}
      </Row>
    </Form>
  );
});
export default BoxType;
