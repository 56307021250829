import { useGlobalState } from '@easygo/components';
import type { API } from '@easygo/service/src/apis/api/typings';
import { PLATFORM_TYPE } from '@easygo/utils/src/enums';
import { useCallback, useMemo } from 'react';
import type { BusinessType } from '../constants/auth';

const enum CertificationGroupKey {
  RailwayBuyer = '66786805390823424',
  RailwaySaler = '66786805885751296',
  RentBoxBuyer = '65361332416860160',
  RentBoxSaler = '69182222371966976',
  MarineCabinBuyer = '66786805663453184',
  MarineCabinSaler = '66786806112243712',
}

const enum CertificationCode {
  Reality = 'real_authentication',
  GrantAuthorization = 'enterprise_delegate',
  RailwayBuyer = 'railway_cabin_buyer',
  RailwaySaler = 'railway_cabin_seller',
  RentBoxBuyer = 'rental_box_buyer',
  RentBoxSaler = 'rental_box_seller',
  MarineCabinBuyer = 'sea_hold_buyer',
  MarineCabinSaler = 'sea_hold_seller',
}

export interface CertificationGroupItem {
  key: CertificationGroupKey;
  platformType: PLATFORM_TYPE;
  businessType: BusinessType;
}

const CERTIFICATION_GROUPS: CertificationGroupItem[] = [
  { key: CertificationGroupKey.RailwayBuyer, platformType: PLATFORM_TYPE.BUIER, businessType: 'railway' },
  { key: CertificationGroupKey.RentBoxBuyer, platformType: PLATFORM_TYPE.BUIER, businessType: 'rentBox' },
  { key: CertificationGroupKey.MarineCabinBuyer, platformType: PLATFORM_TYPE.BUIER, businessType: 'marineCabin' },
  { key: CertificationGroupKey.RailwaySaler, platformType: PLATFORM_TYPE.SALER, businessType: 'railway' },
  { key: CertificationGroupKey.RentBoxSaler, platformType: PLATFORM_TYPE.SALER, businessType: 'rentBox' },
  { key: CertificationGroupKey.MarineCabinSaler, platformType: PLATFORM_TYPE.SALER, businessType: 'marineCabin' },
];

export interface CertificationCodeItem {
  key: CertificationCode;
  platformType: PLATFORM_TYPE;
  businessType: BusinessType;
}

const CERTIFICATION_CODES: CertificationCodeItem[] = [
  { key: CertificationCode.RailwayBuyer, platformType: PLATFORM_TYPE.BUIER, businessType: 'railway' },
  { key: CertificationCode.RentBoxBuyer, platformType: PLATFORM_TYPE.BUIER, businessType: 'rentBox' },
  { key: CertificationCode.MarineCabinBuyer, platformType: PLATFORM_TYPE.BUIER, businessType: 'marineCabin' },
  { key: CertificationCode.RailwaySaler, platformType: PLATFORM_TYPE.SALER, businessType: 'railway' },
  { key: CertificationCode.RentBoxSaler, platformType: PLATFORM_TYPE.SALER, businessType: 'rentBox' },
  { key: CertificationCode.MarineCabinSaler, platformType: PLATFORM_TYPE.SALER, businessType: 'marineCabin' },
];

type CertificationType = CertificationCode.Reality | CertificationCode.GrantAuthorization;

export interface CertificationGroupParams {
  platformType: PLATFORM_TYPE;
  businessType: BusinessType;
}
export function getCertificationGroupKey({ platformType, businessType }: CertificationGroupParams) {
  const certificationGroup = CERTIFICATION_GROUPS.find((item) => item.businessType == businessType && item.platformType === platformType);
  return certificationGroup?.key;
}

export function getCertificationCode({ platformType, businessType }: CertificationGroupParams) {
  const certificationCode = CERTIFICATION_CODES.find((item) => item.businessType == businessType && item.platformType === platformType);
  return certificationCode?.key;
}

export interface GetCertificationGroupParams extends CertificationGroupParams {
  enterprise: API.GetEnterpriseCertificationGroupResponseVO;
}
export function getCertificationGroup({ enterprise, platformType, businessType }: GetCertificationGroupParams) {
  const certificationGroupKey = getCertificationGroupKey({ platformType, businessType });
  if (!certificationGroupKey) {
    return undefined;
  }

  return enterprise?.groupList?.find((item) => item.groupId === certificationGroupKey);
}

export interface CheckAuthorizationPrams extends GetCertificationGroupParams {
  certificationType: CertificationType;
}
export function checkAuthorization({ enterprise, platformType, businessType, certificationType }: CheckAuthorizationPrams) {
  const targetGroup = getCertificationGroup({ enterprise, platformType, businessType });
  if (!targetGroup) {
    return false;
  }

  const targetCert = targetGroup.types.find((item) => item.certificationCode === certificationType);
  return !!targetCert && targetCert.status === 'CERTIFICATION_STATUS_TYPE_COMPLETED';
}

/**
 * 获取业务线所有签署协议
 */
export function getAgreements({ enterprise, platformType, businessType }: GetCertificationGroupParams) {
  const targetGroup = getCertificationGroup({ enterprise, platformType, businessType });
  if (!targetGroup) {
    return undefined;
  }

  const certificationCodeKey = getCertificationCode({ platformType, businessType });
  if (!certificationCodeKey) {
    return undefined;
  }
  const targetType = targetGroup.types.find((item) => item.certificationCode === certificationCodeKey);
  if (!targetType) {
    return undefined;
  }

  return {
    type: targetType,
    list: targetType.resources,
  };
}

/**
 * 获取业务线未签署协议
 */
export function getUnsignedAgreements({ enterprise, platformType, businessType }: GetCertificationGroupParams) {
  const agreements = getAgreements({ enterprise, platformType, businessType });
  if (!agreements) {
    return undefined;
  }

  return {
    type: agreements.type,
    list: agreements.list.filter((item) => item.status !== 'SIGN_STATUS_FINISH'),
  };
}

export function useCheckEnterpriseAuthorization() {
  const {
    state: { bussiness },
  } = useGlobalState();

  const checkHasAuthenticityVerification = useCallback(
    ({ platformType, businessType }: CertificationGroupParams) =>
      !!bussiness?.saler &&
      checkAuthorization({
        platformType,
        businessType,
        enterprise: bussiness.saler,
        certificationType: CertificationCode.Reality,
      }),
    [bussiness]
  );

  const checkHasGrantAuthorization = useCallback(
    ({ platformType, businessType }: CertificationGroupParams) =>
      !!bussiness?.saler &&
      checkAuthorization({
        platformType,
        businessType,
        enterprise: bussiness.saler,
        certificationType: CertificationCode.GrantAuthorization,
      }),
    [bussiness]
  );

  const checkAgreements = useCallback(
    ({ platformType, businessType }: CertificationGroupParams) => {
      if (!bussiness?.saler) {
        return undefined;
      }
      return getAgreements({ enterprise: bussiness.saler, platformType, businessType });
    },
    [bussiness]
  );

  const checkUnsignedAgreements = useCallback(
    ({ platformType, businessType }: CertificationGroupParams) => {
      if (!bussiness?.saler) {
        return undefined;
      }
      return getUnsignedAgreements({ enterprise: bussiness.saler, platformType, businessType });
    },
    [bussiness]
  );

  const checkIsESignNeeded = useCallback(
    ({ platformType, businessType }: CertificationGroupParams) => {
      if (!bussiness?.saler) {
        return false;
      }
      const unsignedAgreements = getUnsignedAgreements({ enterprise: bussiness.saler, platformType, businessType });
      return !!unsignedAgreements && unsignedAgreements.list.some((item) => item.method === 'SIGN_TYPE_ONLINE');
    },
    [bussiness]
  );

  const { signerName, signerMobile, signerId } = useMemo(() => (bussiness?.saler ? bussiness.saler : {}), [bussiness]);

  return {
    /** 签约人id */
    signerId,
    /** 签约人姓名 */
    signerName,
    /** 签约人电话 */
    signerMobile,
    /** 校验是否通过真实性认证 */
    checkHasAuthenticityVerification,
    /** 校验是否通过授权认证 */
    checkHasGrantAuthorization,
    /**获取业务线所有签署协议 */
    checkAgreements,
    /** 获取未签署协议 */
    checkUnsignedAgreements,
    /** 校验买家是否需要签E签宝 */
    checkIsESignNeeded,
  };
}
