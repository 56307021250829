import { Button } from 'antd';
import React from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';

export const OrderEmpty = ({
  onBtn = () => {
    window.location.href = '/rentbox';
  },
  className = '',
  ...resProps
}: { onBtn?: () => any } & React.HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation('common');
  return (
    <div className={`${styles.empty} ${className}`} {...resProps}>
      <img src="/images/status/rentbox-empty.svg" alt="" />
      <p className={styles.txt}>{t('no_orders_to_buy')}</p>
      {/* <Button type="primary" onClick={onBtn}>
        {t('btn_apply_order')}
      </Button> */}
    </div>
  );
};

export const GoodsEmpty = ({ className = '', ...resProps }: React.HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation('common');
  return (
    <div className={`${styles.empty} ${className}`} {...resProps}>
      <img src="/images/status/goods-empty.svg" alt="" />
      <p style={{ marginTop: '35px' }}>{t('no_goods')}</p>
    </div>
  );
};

export const MessageEmpty = ({ className = '', ...resProps }: React.HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation('common');
  return (
    <div className={`${styles.message} ${className}`} {...resProps}>
      <img src="/images/status/message-empty.svg" alt="" />
      <p style={{ marginTop: '24px' }}>{t('not_message_tip')}</p>
    </div>
  );
};

export const NormalEmpty = ({ className = '', ...resProps }: React.HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation('common');
  return (
    <div className={`${styles.message} ${className}`} {...resProps}>
      <img src="/images/status/normal-empty.svg" alt="" />
      <p style={{ marginTop: '24px' }}>{t('table_no_data')}</p>
    </div>
  );
};
