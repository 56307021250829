import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import type { BannerPositionType } from '@easygo/utils/src/enums';
import { useRequest } from 'ahooks';
import type { apis } from '@easygo/service';
import type { CSSProperties } from 'react';
import { useEffect, useState } from 'react';

import styles from './index.module.less';

/**
 * banner轮播（传入type，会直接请求接口）
 * @param imgs 轮播元素
 * @param type 请求广告接口得类型
 * @param width 轮播图长度
 * @param height 轮播图高度
 */
export interface BannerSwiperProps {
  imgs?: apis.API.AdvertisementMaterialVo[];
  type?: BannerPositionType;
  width?: string;
  height?: string;
  styleObj?: CSSProperties;
  bannerClick?: (banner: any) => void;
  req?: any;
}

const BannerSwiper: React.FC<BannerSwiperProps> = ({ imgs, type, width = '100%', height = '100%', styleObj, req, bannerClick }) => {
  const [materials, setMaterials] = useState<apis.API.AdvertisementMaterialVo[]>([]);

  const { run } = useRequest(req, {
    manual: true,
    onSuccess(data) {
      setMaterials(data?.materials || []);
    },
  });

  useEffect(() => {
    if (imgs) {
      setMaterials(imgs);
    } else {
      run({ position: type });
    }
  }, [imgs, type]);

  return (
    <>
      {materials.length > 0 && (
        <div style={styleObj}>
          <Swiper
            loop={materials.length > 1}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
          >
            {materials.map((item, key: number) => (
              <SwiperSlide key={key}>
                <div
                  className={styles.divContainer}
                  style={{ backgroundImage: `url(${item.imgUrl})`, height, cursor: !!item.link ? 'pointer' : 'default' }}
                  onClick={() => {
                    if (!!item.link) {
                      bannerClick?.({ ...item, index: key++ });
                      window.open(item.link);
                    }
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
};

export default BannerSwiper;
