import { i18n } from '@easygo/i18n';
import { Tabs, Empty, Spin, Tooltip } from 'antd';
import { useMemo, useCallback, useState, useRef } from 'react';
import { useRequest, useMemoizedFn, useControllableValue } from 'ahooks';
import type { SelectProps, TabsProps } from 'antd';
import classNames from 'classnames/bind';
import styles from './index.module.less';
import { useComponentTrans } from '@easygo/components';
import { Select } from '@easygo/components/src/select';
import { sharedApiConfig } from '@easygo/config/commonApiConfig';

const cx = classNames.bind(styles);
type TabItemConfig = Required<TabsProps>['items'];
export interface SelectPortProps extends SelectProps {
  locale?: string;
  /** 是否通过城市搜港口、港口搜城市 */
  isMatchPortAndCity?: boolean;
  /** 运营平台 城市港口接口不一样 标识即用运营平台的接口， */
  isOperateFuzzyCity?: boolean;
  /** harbours接口不一样 标识即用harboursNotIncludedCity接口， */
  isBarboursNot?: boolean;
}

const formatSearchText = (keyword: string, str?: string) => {
  return (str ?? '').replace(keyword, `<span style="color:#0CD8D0">${keyword}</span>`);
};

export function SelectPort(props: SelectPortProps) {
  const { locale = 'zh', isMatchPortAndCity, ...restProps } = props;
  const [value, setValue] = useControllableValue(props);
  const [tab, setTab] = useState<string>();
  const cacheRef = useRef<sale.API.PageAddressVO[]>([]);
  const [open, setOpen] = useControllableValue<boolean>(props, {
    defaultValue: false,
    valuePropName: 'open',
    trigger: 'onDropdownVisibleChange',
  });
  const [keyword, setKeyword] = useState<string>();
  const ref = useRef<string>();
  const [selectValue, setSelectValue] = useState<sale.API.PageAddressVO[]>([]);
  const trans = useComponentTrans();
  const currentValue = useMemo<sale.API.PageAddressVO | undefined>(() => {
    return selectValue[selectValue.length - 1];
  }, [selectValue]);
  const setSelectValueFn = useMemoizedFn((value: sale.API.PageAddressVO[]) => {
    setSelectValue(value);
    setTab(`${value[value.length - 1]?.id ?? 'init'}`);
    cacheRef.current = value;
  });
  const handleItemClick = useMemoizedFn((value: sale.API.PageAddressVO) => {
    const newValue = [...selectValue];
    if (value.level == 4) {
      portServer.run('', value.id);
      setSelectValueFn([
        ...newValue,
        {
          ...value,
          nameEn: 'Port',
          nameZh: trans('port'),
        },
      ]);
    } else {
      if (currentValue && currentValue.level === value.level) {
        newValue.splice(selectValue.length - 1, 1, value);
      } else {
        newValue.push(value);
      }
      setSelectValueFn(newValue);
    }
  });
  const cityServer = useRequest(
    async () => {
      if (currentValue?.level == 4) {
        return { records: [] };
      }
      return await sharedApiConfig.apiConfig.pageAddress({
        query: {
          size: '-1',
          parentId: `${currentValue?.id ?? 0}`,
          level: parseInt(`${cacheRef.current[cacheRef.current.length - 1]?.level ?? 0}`) + 1,
        },
      });
    },
    {
      ready: open,
      refreshDeps: [currentValue],
    }
  );
  const portServer = useRequest(
    async (keyword: string, addressId?: string) => {
      const req = props.isBarboursNot ? sharedApiConfig.apiConfig.pageHarbourNotIncludedCity : sharedApiConfig.apiConfig.pageHarbour;
      const res = await req({
        query: {
          [locale === 'en' ? 'nameEn' : 'nameZh']: keyword,
          addressId,
          size: '100',
        },
      });
      return res?.records?.map((item) => ({
        ...item,
        label: locale === 'en' ? item.nameEn : item.nameZh,
        value: item.id,
      }));
    },
    {
      manual: true,
      debounceWait: 500,
    }
  );
  const fuzzServer = useRequest(
    async (name?: string) => {
      if (name) {
        const req = sharedApiConfig.apiConfig.nameFuzzyCityPortOptions;
        const res: sale.API.ShipAddAndPortRes[] | undefined = await req({ name });
        return res?.map((item) => {
          return {
            ...item,
            label: locale === 'en' ? item.nameEn : item.nameZh,
            value: item.id,
          };
        });
      }
      return [];
    },
    {
      manual: true,
      debounceWait: 500,
    }
  );
  const handleTabClick = useMemoizedFn((tab: string) => {
    const index = selectValue.findIndex((item) => `${item.id}` === tab);
    if (index === 0) {
      setSelectValueFn([]);
      setTab('init');
    } else {
      const newValue = selectValue.slice(0, index + 1);
      setSelectValueFn(newValue);
      setTab(`${newValue[newValue.length - 1].id}`);
    }
  });
  const contentRender = useMemo(() => {
    if (currentValue?.level == 4) {
      return portServer.data?.length ? (
        portServer.data.map((item) => (
          <div
            onClick={() => {
              setValue(item);
              setOpen(false);
            }}
            key={item.value}
            className={cx('option', { selected: selectValue.some((it) => it.id === item.value) })}
          >
            {item.label}
          </div>
        ))
      ) : (
        <Empty />
      );
    }
    return cityServer.data?.records?.length ? (
      cityServer.data.records.map?.((item) => {
        return (
          <Tooltip title={locale === 'zh' ? item.nameZh : item.nameEn} key={item.id}>
            <div
              onClick={() => handleItemClick(item)}
              key={item.id}
              className={cx('option', { selected: selectValue.some((it) => it.id === item.id) })}
            >
              {locale === 'zh' ? item.nameZh : item.nameEn}
            </div>
          </Tooltip>
        );
      })
    ) : (
      <Empty />
    );
  }, [cityServer.data?.records, currentValue?.level, handleItemClick, locale, portServer.data, selectValue, setOpen, setValue]);

  const tabs = useMemo<TabItemConfig>(() => {
    if (selectValue.length) {
      return selectValue.map((item) => {
        return {
          label: locale === 'zh' ? item.nameZh : item.nameEn,
          key: `${item.id}`,
        };
      });
    }
    return [
      {
        label: trans('select_default_placeholder'),
        key: 'init',
      },
    ];
  }, [locale, selectValue, trans]);
  const fuzzData = useMemo(() => {
    return (isMatchPortAndCity ? fuzzServer : portServer) ?? [];
  }, [fuzzServer, isMatchPortAndCity, portServer]);
  const dropdownRender = useCallback(() => {
    const prefixCity = locale === 'en' ? 'City' : i18n?.t('城市');
    const prefixPort = locale === 'en' ? 'Port' : i18n?.t('港口');
    return keyword ? (
      <div className={styles.selectWrap}>
        <Spin spinning={fuzzData.loading}>
          {fuzzData.data?.map((item) => (
            <div
              key={item.id}
              className={styles.selectItem}
              dangerouslySetInnerHTML={{
                __html:
                  formatSearchText(
                    keyword,
                    `${['CITY', 1].includes((item as any).type) ? prefixCity : prefixPort}/${locale === 'zh' ? item.nameZh : item.nameEn}`
                  ) ?? '',
              }}
              onClick={() => {
                setValue(item);
                setOpen(false);
              }}
            ></div>
          ))}
          {!fuzzData.data?.length ? <Empty /> : null}
        </Spin>
      </div>
    ) : (
      <div className={styles.dropdownWrap}>
        <Tabs activeKey={tab} destroyInactiveTabPane onTabClick={handleTabClick} tabBarGutter={0} type="card" items={tabs}></Tabs>
        <div className={styles.content}>{contentRender}</div>
      </div>
    );
  }, [keyword, fuzzData.loading, fuzzData.data, tab, handleTabClick, tabs, contentRender, locale, setValue, setOpen]);
  const handleDropdownVisibleChange = useMemoizedFn((open: boolean) => {
    setOpen(open);
    setKeyword('');
  });
  return (
    <Select
      onDropdownVisibleChange={handleDropdownVisibleChange}
      open={open}
      dropdownMatchSelectWidth={530}
      showSearch
      labelInValue
      value={value}
      onChange={(value, option) => {
        setValue(option);
      }}
      style={{
        width: '100%',
      }}
      onSearch={(keyword) => {
        ref.current = keyword;
        setKeyword(keyword);
        fuzzData.run(keyword);
      }}
      {...restProps}
      dropdownRender={dropdownRender}
    />
  );
}
