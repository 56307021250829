import { Tickets as Tickets_ } from '@easygo/components';
import { useGlobalState } from '@easygo/components';
import { useRequest } from 'ahooks';
import { useTranslation } from 'next-i18next';
import { utils } from '@easygo/utils';
import React from 'react';

const { resolveCurrency } = utils;
type Params = Parameters<any>[0];

export function Tickets({
  params = {},
  t,
  children,
  req,
  ...resProps
}: { params?: Params; t: any; req: any; children?: (tickets: any[]) => React.ReactNode } & Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>) {
  const {
    state: { bussinessList, bussiness },
  } = useGlobalState();
  const { data: { orderCoupons = [], productCoupons = [] } = {} } = useRequest(req, {
    defaultParams: [params],
    ready: bussinessList?.length! > 0 && !!bussiness,
  });
  // const { t } = useTranslation('common');
  const tickets = [...orderCoupons, ...productCoupons].map((coupon) => {
    const label = t(coupon.type);
    let desc: string = t('no_limit_canuse');
    if (coupon.quota) {
      if (coupon?.quota && +coupon?.quota.price > 0) {
        desc = t('above_canuse', { val: resolveCurrency(coupon?.quota) });
      }
    }
    return {
      label,
      desc,
      literal: coupon.literal,
      discount: coupon.discount,
    };
  }) as any;

  return (
    <>
      <Tickets_ tickets={tickets} t={t} {...resProps} />
      {children?.(tickets)}
    </>
  );
}
