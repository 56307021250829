import { i18n } from '@easygo/i18n';
import { Fragment, useContext, useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import type { FC } from 'react';
import cls from 'classnames';
import { Form, Button, Space, Tag } from 'antd';
import type { SelectProps, DatePickerProps, FormItemProps } from 'antd';
import { IconFont, Select, DatePicker, Auth } from '@easygo/components';
import { SwapRightOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import { useDebounceFn } from 'ahooks';
import { SelectAddress } from '@easygo/components';
import { useBoxType } from '../../hooks/useBoxType';
import { COMPONENT_TYPE } from './constance';
import styles from './index.module.less';
import { ConfigApisContext } from '../../contexts/config-apis';

type FieldProps = SelectProps & DatePickerProps;
const { RangePicker } = DatePicker;
interface ItemProps {
  /** 组件类型 */
  type: 'CITY_OR_PORT' | 'BOX_TYPE' | 'RANGE_PICKER' | 'ORIGIN_PORT' | 'ORIGIN_CITY';
  formItemProps?: FormItemProps | FormItemProps[];
  fieldProps?: FieldProps;
}
interface BaseSearchProps {
  /** 组件配置 */
  formItem: ItemProps[];
  /** 缓存名称 */
  cacheName?: string;
  /** 搜索方法 */
  handleSearch: (info: any) => void;
  /** 历史数据点击回调 */
  clickCache?: (params: History) => void;
  /** 缓存数量 */
  cacheCount?: number;
  /** 展示缓存字段名称 */
  displayCacheName?: 'nameZh' | 'nameEn';
  /** 自定义form实例 */
  form?: any;
  /** 初始化数据 */
  initValue?: any;
  /** 搜索框下面的额外元素 */
  extra?: ReactNode;
  /** 港口、城市、车站请求方法 */
  selectDataFn?: (keyword: string) => Promise<any[]>;
  /** 是否显示搜搜按钮 */
  showSearchBtn?: boolean;
  /** 间距 */
  gap?: number;
}
const customAreaStyle = {
  width: '240px',
  height: '46px',
  fontFamily: 'D-DIN-Bold',
  border: '1px solid #e8e9ee',
  borderRadius: '8px',
};
const dropdownStyle = {
  fontWeight: 'normal',
  lineHeight: '24px',
};
const { CITY_OR_PORT, BOX_TYPE, RANGE_PICKER } = COMPONENT_TYPE;
const BaseSearch: FC<BaseSearchProps> = (props) => {
  const {
    handleSearch,
    clickCache,
    formItem = [],
    cacheName = '',
    cacheCount = 5,
    form: customForm,
    initValue,
    extra,
    selectDataFn,
    displayCacheName = 'nameZh',
    showSearchBtn = true,
    gap = 16,
  } = props ?? {};
  const { pageBoxType } = useContext(ConfigApisContext)!;
  const [form] = Form.useForm();
  const [_, boxInfo] = useBoxType({
    api: pageBoxType,
  }) as any;
  const [cache, setCache] = useState([]);
  const formatBoxInfo = (boxInfo || []).map((item: any) => ({ ...item, label: item.name, value: item.id }));

  useEffect(() => {
    const storage = JSON.parse(localStorage.getItem(cacheName) || '[]');
    setCache(storage);
    initValue && (customForm || form).setFieldsValue(initValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheName, form, customForm]);

  /**
   * @method 组件配置
   */
  const formItemMapping = {
    [CITY_OR_PORT]: (props: FieldProps) => (
      <SelectAddress
        dropdownStyle={dropdownStyle}
        className={styles.area_select}
        placeholder={props?.placeholder ?? i18n?.t('请选择')}
        {...props}
        onSearch={(keyword: string) => selectDataFn?.(keyword) || Promise.resolve([])}
      />
    ),

    [BOX_TYPE]: (props: FieldProps) => {
      return (
        <Select
          suffixIcon={<IconFont type="easygo-icon-shurukuangneixiala2" style={{ fontSize: 12 }} />}
          popupClassName={styles.select}
          options={formatBoxInfo ?? []}
          placeholder={i18n?.t('请选择箱型')}
          labelInValue
          style={{ minWidth: 120 }}
          dropdownMatchSelectWidth={false}
          {...props}
        />
      );
    },
    [RANGE_PICKER]: (props: FieldProps) => <RangePicker placeholder={[i18n?.t('开始时间'), i18n?.t('结束时间')]} {...props} format={'YYYY-MM-DD'} />,
  };

  /**
   * @method 表单渲染
   */
  const renderItem = () => {
    return formItem.map((item) => {
      const { formItemProps = [] } = (item ?? {}) as any;
      if (Array.isArray(formItemProps) && formItemProps.length) {
        return (
          <Space key={uuid()} size={0}>
            {(formItemProps || []).map((fields: any, index: number) => (
              <Fragment key={uuid()}>
                <Form.Item
                  key={uuid()}
                  {...fields}
                  className={cls(styles.center, { [styles.select_left]: index === 0, [styles.select_right]: index === formItemProps.length - 1 })}
                >
                  {formItemMapping[item.type as keyof typeof COMPONENT_TYPE](
                    {
                      ...fields,
                      style: {
                        ...customAreaStyle,
                        borderRight: index === 0 ? 'none' : 'bolck',
                        borderLeft: index === formItemProps.length - 1 ? 'none' : 'bolck',
                        borderTopRightRadius: index === 0 ? 0 : 8,
                        borderBottomRightRadius: index === 0 ? 0 : 8,
                        borderTopLeftRadius: index === formItemProps.length - 1 ? 0 : 8,
                        borderBottomLeftRadius: index === formItemProps.length - 1 ? 0 : 8,
                        ...fields?.style,
                      },
                    }!
                  )}
                </Form.Item>
                <Auth auth={index !== formItemProps.length - 1}>
                  <SwapRightOutlined className={styles.line_icon} />
                </Auth>
              </Fragment>
            ))}
          </Space>
        );
      }
      return (
        <Form.Item key={uuid()} {...item.formItemProps}>
          {formItemMapping[item.type as keyof typeof COMPONENT_TYPE](item.formItemProps as any)}
        </Form.Item>
      );
    });
  };

  /**
   * @method 搜索
   */
  const { run: handleSubmit } = useDebounceFn(
    async () => {
      const values = await (customForm || form).validateFields();
      const isSearch = Object.values(values).find((item) => !!item);
      handleSearch?.(values);
      if (isSearch) {
        cacheName && handleCache?.(values);
      }
    },
    { wait: 300 }
  );

  /**
   * @method 查找表单动态缓存字段名
   */
  const filterRuleFields = () => {
    const cacheRule: string[] = [];
    formItem.forEach((item: ItemProps) => {
      const { type = '', formItemProps = [] } = (item ?? {}) as any;
      if (CITY_OR_PORT === type) {
        (formItemProps || []).forEach((formItem: FormItemProps) => {
          formItem?.name && cacheRule.push(formItem?.name as string);
        });
      }
    });
    return cacheRule;
  };

  /**
   * @method 历史记录
   */
  const handleCache = (params: any) => {
    const dataTypeInfo = (formItem.find((item) => item.type === RANGE_PICKER) ?? {}) as any;
    const { name } = dataTypeInfo?.formItemProps || {};
    name && delete params[name as string];
    try {
      let newHis: History[] = [];
      const storage = JSON.parse(localStorage.getItem(cacheName) || '[]');
      const rules = filterRuleFields() ?? [];
      newHis = [...storage];
      const fieldsKey = rules.map((rule: string) => `${rule}_${params[rule]?.id}`)?.join('_');
      const sameInfo = storage.find((item: any) => item?.key === fieldsKey);
      if (sameInfo) {
        const index = storage.findIndex((item: any) => item.key === fieldsKey);
        newHis.splice(index, 1);
      }
      newHis.unshift({ ...params, key: fieldsKey });
      newHis.splice(cacheCount);
      localStorage.setItem(cacheName, JSON.stringify(newHis));
      setCache(newHis as []);
    } catch (error) {
      throw error;
    }
  };

  /**
   * @method 删除历史数据
   * @param info
   */
  const handleClose = (info: any) => {
    try {
      const cacheList = cache.filter((item: any) => item?.key !== info?.key);
      localStorage.setItem(cacheName, JSON.stringify(cacheList));
      setCache(cacheList);
    } catch (error) {
      throw error;
    }
  };

  /**
   * @method 历史数据点击
   * @param info
   */
  const handleClickCache = (info: any) => {
    try {
      (customForm || form)?.resetFields();

      setTimeout(() => {
        (customForm || form)?.setFieldsValue({ ...info });
      }, 10);

      clickCache?.(info);
    } catch (error) {
      throw error;
    }
  };

  /**
   * @method 历史记录渲染
   * @returns
   */
  const renderCache = () => {
    try {
      const historyList: any = [];
      if (!Array.isArray(cache)) {
        // 存在不满足数据格式 - 清除存在的脏数据
        localStorage.removeItem(cacheName);
      } else {
        cache.forEach((item: any) => {
          const targetInfo: any = [];
          (filterRuleFields() || []).map((rule: any) => {
            if (item?.[rule]) targetInfo.push(item[rule]);
          });
          targetInfo.length &&
            historyList.push(
              <Tag key={uuid()} closable onClose={() => handleClose(item)} onClick={() => handleClickCache(item)} className={styles.cache_tag}>
                <span className={styles.tag_content}>{targetInfo.map((info: any) => info[displayCacheName ?? 'nameZh'])?.join('-')}</span>
              </Tag>
            );
        });
      }
      return historyList;
    } catch (error) {
      throw error;
    }
  };

  return (
    <Auth auth={!!(Array.isArray(formItem) && formItem.length)}>
      <div className={styles.search_container}>
        <Form name="basicSearch" layout="vertical" form={customForm || form} requiredMark={false}>
          <Space className={styles.form_item_container} size={gap}>
            {renderItem()}
            {showSearchBtn && (
              <Button
                type="primary"
                icon={<IconFont className={styles.search_icon} type="easygo-icon-sousuo" />}
                onClick={handleSubmit}
                className={styles.search_btn}
              >
                {i18n?.t('搜索')}
              </Button>
            )}
          </Space>
        </Form>
        <div className={styles.footer}>
          <div>
            <Auth auth={!!(Array.isArray(cache) && cache.length)}>
              <div className={styles.cache_container}>
                <span className={styles.label}>{i18n?.t('历史')}</span>
                <div>{renderCache()}</div>
              </div>
            </Auth>
          </div>
          <div className={styles.extra}>
            <Auth auth={!!extra}>{extra}</Auth>
          </div>
        </div>
      </div>
    </Auth>
  );
};

export { BaseSearch, COMPONENT_TYPE };
