import styles from './index.module.less';

import type { TooltipProps } from 'antd';
import { Tooltip as AntdTooltip } from 'antd';

const DEFAULT_TOOLTIP_PROPS: TooltipProps = {
  overlayClassName: styles['easy-go-tooltip'],
  overlayInnerStyle: { backgroundColor: '#1A1E2E' },
};

export const Tooltip: React.FC<TooltipProps> = (props) => {
  return <AntdTooltip {...DEFAULT_TOOLTIP_PROPS} {...props}></AntdTooltip>;
};
