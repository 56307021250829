import { useRequest } from 'ahooks';
import { useGlobalState } from '@easygo/components';
import { utils } from '@easygo/utils';
import { useLocation } from 'react-router-dom';
export { useCanPublishGoods } from './usePublishGoods';
export * from './common';

/** 根据local返回nameZh nameEn中一个 */
export const useResolveLocalKey = (
  matchFn = (locale: string) => `name${locale.slice(0, 1).toUpperCase()}${locale.slice(1)}`,
  defaultKey = 'nameZh'
) => {
  const {
    state: { locale },
  } = useGlobalState();
  const resolveLocale = utils.resolveLocaleKeyValue(locale)(matchFn, defaultKey);
  return resolveLocale;
};

/** 获取专区活动详情 */
export function useZoneActivity(req: any, categoryId = '64205420956274688', activityId = '123456789101112') {
  const { data } = useRequest(req, {
    defaultParams: [{ categoryId, activityId }],
  });
  return data;
}
// 获取url search
export const getSearchQuery = (): Record<string, string> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  return Object.fromEntries(urlSearchParams.entries());
};
