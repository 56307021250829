import { useRequest } from 'ahooks';
import type { SelectProps } from 'antd';
import { Select } from 'antd';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { defaultCityIds } from '@easygo/utils';
import { useComponentTrans } from '@easygo/components';
import { CaretDownOutlined } from '@ant-design/icons';
import { sharedApiConfig } from '@easygo/config/commonApiConfig';

interface CitySelectProps extends SelectProps {
  placeholder?: string;
  level?: 'city' | 'province' | 'country';
  value?: string | number | any;
  locale?: string;
  pageSize?: number;
  suffixIcon?: ReactNode;
  /** 境内外 */
  overseasFlag?: sale.API.AddressQuery['overseasFlag'];
  /** 是否需要默认城市参数参与搜索 */
  defaultParams?: any[] | boolean;
  idList?: number[];
  /** 请求方法 */
  serverReq?: any;
}

const levels = {
  city: 4,
  province: 3,
  country: 2,
};

const CitySelect: React.FC<CitySelectProps> = (props) => {
  const trans = useComponentTrans();
  const {
    placeholder = trans('city_default_placeholder'),
    level = 'city',
    overseasFlag,
    pageSize = 15,
    value,
    locale = 'zh',
    suffixIcon = <CaretDownOutlined />,
    onChange,
    defaultParams,
    serverReq = sharedApiConfig.apiConfig.pageAddress,
    ...res
  } = props;

  const i18nKey = locale === 'zh' ? 'nameZh' : 'nameEn';
  const { data: optionsCity, run: fetchCity } = useRequest(
    async ({ keyword, idList }) => {
      const params: sale.API.AddressQuery = {
        level: levels[level],
        nameFuzzy: keyword,
        ...{ idList },
        size: `${pageSize}`,
        overseasFlag,
      };
      const res = await serverReq({
        query: params,
      });
      return (res?.records || []).map((i) => ({ label: i[i18nKey], value: i.id }));
    },
    {
      manual: true,
      debounceWait: 300,
    }
  );

  useEffect(() => {
    // optionsCity 不存在来判定是否是初始化加载，且value不为空，一般场景为编辑值回显时
    if (value) {
      if (!res.mode) {
        // 不是多选
        fetchCity({ idList: [res.labelInValue ? value?.value : value] });
      } else if (!optionsCity?.length) {
        fetchCity({ idList: res.labelInValue ? value.map((i: any) => i.value) : value });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const notFoundContent = <p style={{ margin: 0 }}>{trans('city_default_empty')}</p>;
  return (
    <Select
      placeholder={placeholder}
      options={optionsCity}
      filterOption={false}
      allowClear
      showSearch
      value={optionsCity ? value : undefined}
      onChange={(val, options) => {
        onChange?.(val, options);
      }}
      onSearch={(keyword: string) => {
        fetchCity({ keyword });
      }}
      onDropdownVisibleChange={(open: boolean) => {
        !value &&
          open &&
          fetchCity({ idList: defaultParams !== false ? (typeof defaultParams === 'object' ? defaultParams : defaultCityIds) : undefined });
      }}
      suffixIcon={suffixIcon}
      notFoundContent={notFoundContent}
      {...res}
    />
  );
};

export default CitySelect;
