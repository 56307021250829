import { i18n } from '@easygo/i18n';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import type { FC } from 'react';
import type { apis } from '@easygo/service';
import classNames from 'classnames';
import { Form, Row, Col, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import styles from './index.module.less';
import { DateFormat } from '@easygo/components';
import { CurrencyIcon, InventoryStatus, InventoryStatusLabel } from '@easygo/utils/src/enums/ocean';

interface ShippingDateProps {
  onFieldsChange?: (allValue: any) => void;
  ref?: any;
  title?: string;
  info: apis.API.SkuDTO[];
  type?: string;
  disabled?: boolean; // 是否支持修改
  defaultSelectRowKeys?: string | number; // 默认选中key
}

const columns: ColumnsType<apis.API.SkuDTO> = [
  {
    title: i18n?.t('计划离港'),
    dataIndex: 'planDepartureTime',
    render: (text: string) => (
      <span>
        <DateFormat date={text} format="YYYY-MM-DD HH:mm" />
      </span>
    ),
  },
  {
    title: i18n?.t('航程天数'),
    dataIndex: 'voyageDays',
  },
  {
    title: i18n?.t('航线代码'),
    dataIndex: 'laneCode',
  },
  {
    title: i18n?.t('船名'),
    dataIndex: 'shipName',
    render: (text: string) => <span>{text}</span>,
  },
  {
    title: i18n?.t('价格'),
    dataIndex: 'price',
    render: (text: string, record: apis.API.SkuDTO) => <span>{`${CurrencyIcon[record?.currency as keyof typeof CurrencyIcon]} ${text}` || '-'}</span>,
  },
  {
    title: i18n?.t('库存'),
    dataIndex: 'inventoryStatus',
    render: (text: string) => (
      <span
        className={classNames(styles.status, {
          [styles.out]: text === InventoryStatus.OUT,
          [styles.warning]: text === InventoryStatus.TIGHT,
        })}
      >
        {InventoryStatusLabel[text as keyof typeof InventoryStatusLabel]}
      </span>
    ),
  },
];

const ShippingDate: FC<ShippingDateProps> = forwardRef((props, ref) => {
  const { onFieldsChange, info = [], title, defaultSelectRowKeys = '' } = props ?? {};
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const rowSelection = {
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
      const selectedRows = info?.find((item) => (item?.id as unknown as string) === newSelectedRowKeys[0]);
      onFieldsChange && onFieldsChange(selectedRows);
    },
  };
  useImperativeHandle(ref, () => ({
    shippingDateForm: form,
  }));

  useEffect(() => {
    setSelectedRowKeys([defaultSelectRowKeys]);
  }, [defaultSelectRowKeys]);
  return (
    <>
      {title && <h2>{title}</h2>}
      <Row gutter={24}>
        <Col span={24}>
          <Table
            rowSelection={{
              type: 'radio',
              ...rowSelection,
              selectedRowKeys,
            }}
            rowKey="id"
            pagination={false}
            columns={columns}
            dataSource={info}
          />
        </Col>
      </Row>
    </>
  );
});
export default ShippingDate;
