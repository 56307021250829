import React, { useEffect } from 'react';
import type { FC } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'next-i18next';

const { TextArea } = Input;

interface RemarkProps {
  form: any;
  info?: any;
}
const Remark: FC<RemarkProps> = (props) => {
  const { form, info } = props ?? {};
  const { t } = useTranslation('ocean');

  useEffect(() => {
    form.setFieldsValue(info);
  }, [form, info]);

  return (
    <Form form={form} layout="vertical">
      <div style={{ marginBottom: -24 }}>
        <Form.Item name="remark" label={t('label.order_remarks')} colon={false} required={false}>
          <TextArea placeholder={t('placeholder.enter_order_remarks')} autoSize={{ minRows: 1 }} maxLength={200} />
        </Form.Item>
      </div>
    </Form>
  );
};

export default Remark;
