import { i18n } from '@easygo/i18n';
import { Form, Radio } from 'antd';
import styles from './index.module.less';
import { useEffect } from 'react';

const options_ = [
  { label: i18n?.t('需要'), value: true },
  { label: i18n?.t('不需要'), value: false },
];
interface ButtonRadioProps {
  form: any;
  fieldName?: string;
  initialValue?: boolean | string;
  disabled?: boolean;
  onChange?: any;
  options?: {
    label: string;
    value: any;
  }[];
}

const ButtonRadio = ({ form, fieldName = 'need', initialValue = false, disabled = false, onChange, options = options_ }: ButtonRadioProps) => {
  useEffect(() => {
    form.setFieldValue(fieldName, initialValue);
  }, [initialValue, form, fieldName]);

  const handleChange = (e: any) => {
    onChange?.(e.target.value);
  };

  return (
    <>
      <Form form={form} layout="vertical" disabled={disabled}>
        <div className={styles.buttonsWrapper}>
          <Form.Item name={fieldName}>
            <Radio.Group onChange={handleChange} buttonStyle="solid">
              {options?.map(({ value, label }) => (
                <Radio.Button key={label} value={value}>
                  {label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default ButtonRadio;
