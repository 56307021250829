import { i18n } from '@easygo/i18n';
import { memo, useEffect } from 'react';
import type { FC } from 'react';
import { useRequest } from 'ahooks';
import { Form, Input, Select, Row, Col, Divider, Space, Tabs } from 'antd';
import { useTranslation } from 'next-i18next';
import { ProDatePicker } from '@easygo/components';
import { utils, enums, validator } from '@easygo/utils';
import { IconFont } from '@easygo/components/src/icon-font';
import { ReleaseTypeLabel, TransportModeLabel, PayModeLabel, BillTypeLabel, AttachmentType } from '@easygo/utils/src/enums/ocean';
import dayjs from 'dayjs';
import UploadComponent from './upload';
import styles from './index.module.less';
import cls from 'classnames';
import type { API } from '@easygo/service/src/apis';
interface LetterEntrustmentProps {
  form: any;
  baseInfo?: any; // 托书信息
  dockInfo?: { address: string; name: string }[];
  getAttachmentApi?: () => Promise<API.PerformanceOrderAttachmentVo[]>;
  isGoodsInfo?: boolean;
}
const { TextArea } = Input;
const LetterEntrustment: FC<LetterEntrustmentProps> = memo((props) => {
  const { form, baseInfo = {}, getAttachmentApi, isGoodsInfo } = props ?? {};
  const { t } = useTranslation('ocean');
  const { data: fileInfo = [] } = useRequest(
    async () => {
      if (getAttachmentApi) {
        return getAttachmentApi().then((list) => {
          const last = list?.sort((a, b) => dayjs(a.uploadTime).valueOf() - dayjs(b.uploadTime).valueOf())?.pop();
          return last ? [last] : [];
        });
      }

      return [];
    },
    { ready: !!getAttachmentApi }
  );

  useEffect(() => {
    form.setFieldsValue({
      bilNumber: 3,
      ...baseInfo,
      docInfo: fileInfo.map((item) => ({ name: item.name, url: item.url, attachmentId: item.attachmentId })),
    });
  }, [form, fileInfo, baseInfo]);

  const disabledDate = (current: any) => {
    return current && current < dayjs().subtract(1, 'days');
  };

  const panelItems = [
    {
      label: i18n?.t('上传托书'),
      key: 'upload',
      children: (
        <Form.Item
          name="docInfo"
          label={
            <div className={styles.uploadTitleContainer}>
              <span>{t('upload_lading')}</span>
              <div>
                <Space>
                  <a download href={enums.OCEAN_SO_TPL}>
                    {t('download_lading_temp')}
                  </a>
                </Space>
              </div>
            </div>
          }
          rules={[
            {
              required: false,
              message: t('placeholder.upload_lading_temp'),
            },
          ]}
          className={styles.uploadContainer}
        >
          <UploadComponent />
        </Form.Item>
      ),
    },
    ...(isGoodsInfo
      ? [
          {
            label: '填写托书信息',
            key: 'info',
            children: (
              <div className={styles.letter_box}>
                <div className={cls('letter_container', 'show_letter_container')}>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        name="numberOf"
                        label={t('label.number')}
                        colon={false}
                        required={false}
                        rules={[
                          {
                            required: false,
                            message: t('placeholder.enter_number'),
                          },
                          {
                            pattern: validator.integerReg,
                            message: t('tips.only_numbers'),
                          },
                        ]}
                      >
                        <Input placeholder={t('placeholder.enter_number')} maxLength={8} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="grossWeight"
                        label={t('label.gross_weight')}
                        colon={false}
                        required={false}
                        rules={[
                          {
                            required: false,
                            message: t('label.gross_weight'),
                          },
                          {
                            pattern: validator.numberLimitthreeDecimalReg,
                            message: t('tips.only_three_numbers'),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t('label.gross_weight')}
                          addonAfter={<IconFont type="easygo-icon-kg" className={styles.inputIcon} />}
                          maxLength={12}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        name="measurement"
                        label={t('label.measurement')}
                        colon={false}
                        required={false}
                        rules={[
                          {
                            required: false,
                            message: t('placeholder.enter_measurement'),
                          },
                          {
                            pattern: validator.numberLimitthreeDecimalReg,
                            message: t('tips.only_three_numbers'),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t('placeholder.enter_measurement')}
                          addonAfter={<IconFont type="easygo-icon-volume" className={styles.inputIcon} />}
                          maxLength={12}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="packageUnit"
                        label={t('label.ship_package')}
                        colon={false}
                        required={false}
                        rules={[
                          {
                            required: false,
                            message: t('placeholder.enter_ship_package'),
                          },
                        ]}
                      >
                        <Input placeholder={t('placeholder.enter_ship_package')} maxLength={100} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        name="stockUptime"
                        label={t('label.goods_time')}
                        colon={false}
                        required={false}
                        rules={[
                          {
                            required: false,
                            message: t('placeholder.enter_goods_time'),
                          },
                          {
                            validator: async (_, value) => {
                              if (value && value < dayjs().subtract(1, 'days')) {
                                return Promise.reject(new Error('货好日期不能小于当前时间'));
                              }
                            },
                          },
                        ]}
                      >
                        <ProDatePicker
                          placeholder={t('placeholder.enter_goods_time')}
                          style={{ width: '100%' }}
                          format={'YYYY-MM-DD'}
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                      <Form.Item
                        name="billType"
                        label={t('label.bill_type')}
                        colon={false}
                        required={false}
                        rules={[
                          {
                            required: false,
                            message: t('placeholder.select_bill_type'),
                          },
                        ]}
                      >
                        <Select placeholder={t('placeholder.select_bill_type')} options={utils.getEnumsLabelValue(BillTypeLabel)} />
                      </Form.Item>
                    </Col> */}
                    <Col span={12}>
                      <Form.Item
                        name="releaseType"
                        label={t('label.release_type')}
                        colon={false}
                        required={false}
                        rules={[
                          {
                            required: false,
                            message: t('placeholder.select_release_type'),
                          },
                        ]}
                      >
                        <Select placeholder={t('placeholder.select_release_type')} options={utils.getEnumsLabelValue(ReleaseTypeLabel)} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        name="transportMode"
                        label={t('label.transport_mode')}
                        colon={false}
                        required={false}
                        rules={[
                          {
                            required: false,
                            message: t('placeholder.select_transport_mode'),
                          },
                        ]}
                      >
                        <Select placeholder={t('placeholder.select_transport_mode')} options={utils.getEnumsLabelValue(TransportModeLabel)} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="paymentMode"
                        label={t('label.payment_mode')}
                        colon={false}
                        required={false}
                        rules={[
                          {
                            required: false,
                            message: t('placeholder.select_payment_mode'),
                          },
                        ]}
                      >
                        <Select placeholder={t('placeholder.select_payment_mode')} options={utils.getEnumsLabelValue(PayModeLabel)} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        name="bilNumber"
                        label={t('label.bl_number')}
                        colon={false}
                        required={false}
                        rules={[
                          {
                            required: false,
                            message: t('placeholder.enter_bl_number'),
                          },
                          {
                            pattern: validator.nunberLessNine,
                            message: t('tips.only_nine_numbers'),
                          },
                        ]}
                      >
                        <Input placeholder={t('placeholder.enter_bl_number')} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item name="englishDesc" label={t('label.english_name')} colon={false} required={false}>
                        <Input placeholder={t('placeholder.enter_english_name')} maxLength={100} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: -24 }}>
                    <Col span={24}>
                      <Form.Item name="goodsRemark" label={t('label.goods_remark')} colon={false} required={false}>
                        <TextArea placeholder={t('placeholder.no_special_requirements')} autoSize={{ minRows: 1 }} maxLength={200} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider dashed />
                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item
                        name="nameForSender"
                        label={t('label.sender')}
                        colon={false}
                        required={false}
                        rules={[
                          {
                            required: false,
                            message: t('enter_company_name'),
                          },
                        ]}
                      >
                        <Input placeholder={t('enter_company_name')} maxLength={200} />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        name="addressForSender"
                        label={t('label.address')}
                        colon={false}
                        required={false}
                        rules={[
                          {
                            required: false,
                            message: t('placeholder.enter_address'),
                          },
                        ]}
                      >
                        <Input placeholder={t('placeholder.enter_address')} maxLength={200} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item
                        name="nameForReceiver"
                        label={t('label.receiver')}
                        colon={false}
                        required={false}
                        rules={[
                          {
                            required: false,
                            message: t('enter_company_name'),
                          },
                        ]}
                      >
                        <Input placeholder={t('enter_company_name')} maxLength={200} />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        name="addressForReceiver"
                        label={t('label.address')}
                        colon={false}
                        required={false}
                        rules={[
                          {
                            required: false,
                            message: t('placeholder.enter_address'),
                          },
                        ]}
                      >
                        <Input placeholder={t('placeholder.enter_address')} maxLength={200} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item
                        name="nameForNotifier"
                        label={t('label.noticer')}
                        colon={false}
                        required={false}
                        rules={[
                          {
                            required: false,
                            message: t('enter_company_name'),
                          },
                        ]}
                      >
                        <Input placeholder={t('enter_company_name')} maxLength={200} />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        name="addressForNotifier"
                        label={t('label.address')}
                        colon={false}
                        required={false}
                        rules={[
                          {
                            required: false,
                            message: t('placeholder.enter_address'),
                          },
                        ]}
                      >
                        <Input placeholder={t('placeholder.enter_address')} maxLength={200} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item name="nameForNotifierSec" label={t('label.noticer_second')} colon={false} required={false}>
                        <Input placeholder={t('enter_company_name')} maxLength={200} />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item name="addressForNotifierSec" label={t('label.address')} colon={false} required={false}>
                        <Input placeholder={t('placeholder.enter_address')} maxLength={200} />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            ),
          },
        ]
      : []),
  ];
  return (
    <Form form={form} layout="vertical" className={styles.placeOrderForm}>
      <Tabs defaultActiveKey="upload">
        {panelItems.map((item) => (
          <Tabs.TabPane tab={item.label} key={item.key} forceRender>
            {item.children}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Form>
  );
});

export default LetterEntrustment;
