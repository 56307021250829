import { i18n } from '@easygo/i18n';
export type BusinessType = 'rentBox' | 'marineCabin' | 'railway' | 'declare' | 'trailer';

export interface MsgConfig {
  title: string;
  content: string;
}

export const orderType2BuyerConfirmMsg: {
  [K in BusinessType]: MsgConfig;
} = {
  rentBox: {
    title: i18n?.t('您暂未完成【购买租箱】服务认证！'),
    content: i18n?.t('完成认证后才能进行交易哟！'),
  },
  marineCabin: {
    title: i18n?.t('您暂未完成【购买海运舱】服务认证！'),
    content: i18n?.t('完成认证后才能进行交易哟！'),
  },
  railway: {
    title: i18n?.t('您暂未完成【购买班列】服务认证！'),
    content: i18n?.t('完成认证后才能进行交易哟！'),
  },
  declare: {
    title: i18n?.t('您暂未完成【购买报关】服务认证！'),
    content: i18n?.t('完成认证后才能进行交易哟！'),
  },
  trailer: {
    title: i18n?.t('您暂未完成【购买拖车】服务认证！'),
    content: i18n?.t('完成认证后才能进行交易哟！'),
  },
};

export const orderType2SalerConfirmMsg: {
  [K in BusinessType]: MsgConfig;
} = {
  rentBox: {
    title: i18n?.t('您暂未完成【售卖租箱商品】服务认证！'),
    content: i18n?.t('认证成功后才能发布商品信息哟！'),
  },
  marineCabin: {
    title: i18n?.t('您暂未完成【售卖海运舱】服务认证！'),
    content: i18n?.t('认证成功后才能发布商品信息哟！'),
  },
  railway: {
    title: i18n?.t('您暂未完成【售卖班列】服务认证！'),
    content: i18n?.t('认证成功后才能发布商品信息哟！'),
  },
  declare: {
    title: i18n?.t('您暂未完成【售卖报关】服务认证！'),
    content: i18n?.t('认证成功后才能发布商品信息哟！'),
  },
  trailer: {
    title: i18n?.t('您暂未完成【售卖拖车】服务认证！'),
    content: i18n?.t('认证成功后才能发布商品信息哟！'),
  },
};
